Object.defineProperty(exports, '__esModule', {
  value: true
});

// Customizable Area Start
exports.apiContentType = 'application/json';
exports.apiFormContentType = 'multipart/form-data';
exports.getApiMethod = 'GET';
exports.postApiMethod = 'POST';
exports.putApiMethod = 'PUT';

exports.getChatListApiEndPoint = 'chat/chats';
exports.showChatApiEndPoint = 'chat/chats';
exports.getChatHistoryApiEndPoint = 'chat/chats/history';
exports.searchMessageApiEndPoint = 'chat/chats/search_messages';
exports.searchChatGroupApiEndPoint = 'chat/chats/search';
exports.createChatRoomApiEndPoint = 'chat/chats';
exports.addUserToChatApiEndPoint = 'chat/chats/add_user';
exports.leaveChatApiEndPoint = 'chat/chats/leave';
exports.sendMessageApiEndPoint = 'chat/chats';
exports.updateReadMessageApiEndPoint = 'chat/chats/read_messages';
exports.updateChatApiEndPoint = 'chat/chats';

exports.getMyChatsApiEndpoint = 'chat/chats/mychats';

exports.createButtonText = 'Create';
exports.createRoomButtonText = 'Create Room'
exports.addButtonText = 'Add';
exports.closeButtonText = 'Close';
exports.addAccountText = 'Add Account';
exports.leaveChatRoomText = 'Leave Chat';
exports.sendText = 'Send';
exports.cancelText = 'Cancel';
exports.errorTitle = 'Error';
exports.errorAllFieldsAreMandatory = 'All fields are mandatory.';
exports.unreadMessageText = "Unread Messages";
exports.lastMessageText = "Last Message:";
exports.writeMessagePlaceholder = "Write message here";
exports.usersText = "Users:";
exports.accountIdPlaceholder = "Account ID";
exports.namePlaceholder = "Name";
exports.messagesHeading = "Messages";
exports.searchPlaceholder = "Search";
exports.dotSymbol = ".";
exports.onlineSymbol = "Online";
exports.typeMessagePlaceholder = "Type message";
exports.newMessage = "New message";
exports.suggestions = "Suggestions";
exports.tabsData = [
  {
    tabName: "All",
    tabId: "all",
    count: 4,
  },
  {
    tabName: "Contracts",
    tabId: "contracts",
    count: 2,
  },
  {
    tabName: "Inquiries",
    tabId: "inquiries",
    count: 1,
  }
];
exports.contactInfo = {
  name: "Aman Sharma",
  activeTime: "4 hours ago",
  text: `
  Lorem Ipsum is simply dummy text of the printing 
  and typesetting industry. Lorem Ipsum has been the industry's 
  standard dummy text ever since the 1500s, when an unknown 
  printer took a galley of type and scrambled it to make a 
  type specimen book.
  `
};
exports.chatText = `
Hi Aman, \n 

We are hiring for Illustrator and Motion graphics Artist in Jodhpur location. If you are interested please connect with me. 
\n
Regards, 
Gunjan Sharma Talent Acquisition 
\n
Zevo360 Technologies Pvt Ltd 
Email - hr2@zevo360.com
`;
exports.timeStamp = "11:38 AM";
exports.userIdsOfSendBird = [
  "7",
  "8",
  "3F0A1410-6329-4AC3-ADFF-AAADE8C5D8E7",
  "ui1",
  "sendbird_desk_agent_id_27b71627-b9f8-48da-8cf9-12f88bb79d9e"
];
exports.photosAndVideosText = "Photos & videos ";
exports.documentText = "Document";
exports.listUserEndPoint = "/bx_block_chat/users/list_user";
exports.channelListEndPoint = "/bx_block_chat/users/my_group_channel";
exports.baseUrl = require("../../../framework/src/config").baseURL;
  // Customizable Area End