import React from "react";

import {
  Container,
  Button,
  Typography,
  InputAdornment,
  // Customizable Area Start
  Grid,
  Box, 
  ThemeProvider, 
  createTheme,
  withStyles,
  Backdrop,
  IconButton,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start

import CircularProgress from '@mui/material/CircularProgress';
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import ToastMSG from "../../../components/src/ToastMSG.web";

import {
  downloadIcon,
  eyeIcon,
  leftIcon,
  loveIcon,
  messageIcon,
  ratingIcon,
  saveIcon,
  shortListIcon,
  unShortListIcon
} from "./assets";

const typoBlack = "#66625C";
const typoWhite = "#FFF2E3"

const theme = createTheme({
  typography: {
    fontFamily: "Inter, sans-serif",
    h2: {
      color: typoWhite,
      fontSize: "1.5rem",
      fontWeight: 700,
      lineHeight: "29.05px",
      textAlign: "left",
      fontFamily: "Inter-SemiBold, sans-serif",
      wordWrap: "break-word",
      overflowWrap: "break-word",
    },
    h4: {
      color: typoWhite,
      fontSize: "1.25rem",
      fontWeight: 700,
      lineHeight: "24.2px",
      textAlign: "left",
      fontFamily: "Inter-SemoBold, sans-serif",
      wordWrap: "break-word",
      overflowWrap: "break-word",
    },
    body1: {
      color: typoWhite,
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: "19.36px",
      textAlign: "left",
      wordWrap: "break-word",
      overflowWrap: "break-word",
    },
    body2: {
      color: typoWhite,
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: "16.94px",
      textAlign: "left",
      wordWrap: "break-word",
      overflowWrap: "break-word",
    },
    caption: {
      color: typoWhite,
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: "14.52px",
      textAlign: "left",
      wordWrap: "break-word",
      overflowWrap: "break-word",
    },
    button: {
      color: `${typoWhite}`,
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: "19.36px",
      textTransform: "none",
      letterSpacing: "0.38px",
    }
  }
})

// Customizable Area End

import Proposalgeneration2Controller, {
  Props,
  configJSON,
  ProposalType
} from "./Proposalgeneration2Controller";


export default class Proposalgeneration2 extends Proposalgeneration2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderShortListText = () => {
    const { proposal_status } = this.state.proposalUserDetails
    const item = proposal_status === "archived" ?
      <Box className={this.props.classes.buttonCon}>
        <Button data-testid="unArchiveButton" className={this.props.classes.archiveButton} onClick={this.handleUnArchived}>{configJSON.unArchiveText}</Button>
      </Box> :
      <Box className={this.props.classes.shortListButtonsCon}>
        <Box className={`${this.props.classes.allPropsCon} ${this.props.classes.marginLeftAuto}`}>
          <img src={(proposal_status !== "shortlisted") ? shortListIcon : unShortListIcon} alt={configJSON.image} />
          <Typography
            data-testid="shortListButton"
            onClick={this.toogleShortListed}
            className={`${this.props.classes.shortListText} ${this.props.classes.underline}`}>
            {(proposal_status !== "shortlisted") ? configJSON.shortListText : configJSON.unShortListText}
          </Typography>
        </Box>
        <Box className={this.props.classes.buttonCon}>
          <Button data-testid="archiveButton" className={this.props.classes.archiveButton} onClick={this.handleArchived}>{configJSON.archiveText}</Button>
          <Button data-testid="offerLetterButton" onClick={this.handleNavigateOfferLetterPage} className={this.props.classes.offerContractButton}>{configJSON.offerContractText}</Button>
        </Box>
      </Box>

    return (item)
  }

  renderCollectionOfProjects = () => {
    
   let renderData = (!!this.state.proposalUserDetails.projects && this.state.proposalUserDetails.projects.length > 0 ) ? this.state.proposalUserDetails.projects.map((collectionData, idIndex) => (
      <Box key={idIndex}>
        <Typography variant="body1" className={this.props.classes.collectionNameText}>
          {collectionData.collection_name}
        </Typography>
        <Box className={this.props.classes.borderBottomCon} />
        <Grid container spacing={4} className={this.props.classes.gridCon}>
          {collectionData.projects.map((collection) => (
            <Grid item lg={4}>
              <Box className={this.props.classes.collectionCard}>
                <img className={this.props.classes.coverImage} src={this.baseUrlStirng+collection.cover_image} alt={configJSON.image} />
                <Box className={this.props.classes.projectNameCon}>
                  <Typography className={this.props.classes.projectName}>{collection.project_title}</Typography>
                  <Box className={this.props.classes.iconsCon}>
                    <Box className={this.props.classes.iconCon}>
                      <img src={eyeIcon} alt={collection.cover_image} />
                      <Typography className={this.props.classes.iconInfo} variant="caption">{collection.views}</Typography>
                    </Box>
                    <Box className={this.props.classes.iconCon}>
                      <img src={loveIcon} alt={configJSON.image} />
                      <Typography className={this.props.classes.iconInfo} variant="caption">{collection.likes}</Typography>
                    </Box>
                    <Box className={this.props.classes.iconCon}>
                      <img src={saveIcon} alt={configJSON.image} />
                      <Typography className={this.props.classes.iconInfo} variant="caption">{collection.saved_count}</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    ))
    : 
    <Box>
      <Typography>{configJSON.noProjectsAvailable}</Typography>
    </Box>
    return renderData;
  }

  renderSkillsCon = () => {
    return (<Box className={this.props.classes.chipCon}>
      {!!this.state.proposalUserDetails.skills && this.state.proposalUserDetails.skills.length > 0 &&
        this.state.proposalUserDetails.skills.map(
          (item) =>
            (<Box key={item.id} className={this.props.classes.chip}><Typography variant="caption">{item.name}</Typography></Box>)
        )}
    </Box>);
  }


  renderBackDrop = () => {
    return (
      <Box
        className={this.props.classes.backdrop} >
        <CircularProgress className="circular" />
      </Box>
    )
  }

  renderAdditionalFiles = () => {
    const resourceFilesLength = this.getLengthOfResourceFile().length;
    return (
      <Box component={"ul"} className={this.props.classes.filesListCon}>
        {resourceFilesLength > 0 && this.getLengthOfResourceFile().map(
          (list, idIndex: number) =>
          (
            <Box key={list.filename} component={"li"} className={`${this.props.classes.fileList}`}>
              <Typography variant="body2" className={this.props.classes.countTypo}>{`${++idIndex}.`}</Typography>
                <Box className={this.props.classes.fileNameCon}>
                  <Box className={this.props.classes.fileCon}>
                    <Typography style={lineBreakToOneLine} component={"span"}>
                      {list.filename}
                    </Typography>
                  </Box>
                <IconButton data-testid="downloadFile" onClick={() =>this.handleDownloadFile(list,"download")}>
                  <img src={downloadIcon} />
                </IconButton>
                </Box>
            </Box>
          )
        )
        }
         {this.state.linksList.map(
          (list , idIndex: number) =>
          (
            <Box key={list.filename} component={"li"} className={`${this.props.classes.fileList}`}>
              <Typography variant="body2" className={this.props.classes.countTypo}>{`${++idIndex + resourceFilesLength}.`}</Typography>
               <Typography style={lineBreakToOneLine} data-testid="navigationLink" onClick={()=>{this.handleDownloadFile(list,"target")}} className={this.props.classes.blueColorText}>
                {list.filename}
              </Typography>
            </Box>
          )
        ) }
      </Box>
    )
  }

  renderPropsDetails = () => {
    return (<Box className={this.props.classes.responsiveCon}>
      <Box className={this.props.classes.revisedAllPropsCon}>
        <Box data-testid = "allProposals" onClick={() => this.handleNavigateToMyWork()} className={`${this.props.classes.allPropsCon} ${this.props.classes.cursonPointer}`}>
          <img src={leftIcon} alt={configJSON.leftIcon} />
          <Typography variant="body2" className={this.props.classes.underline}>{configJSON.allProposalText}</Typography>
        </Box>
        <Typography variant="body2" className={this.props.classes.reviewText}>{this.state.proposalUserDetails.received_time_ago}</Typography>
      </Box>
      <Typography className={this.props.classes.workpostText} variant="h4">{this.state.proposalUserDetails.work_opportunity_title}</Typography>
      <Box className={this.props.classes.middleCon}>
        <Box className={this.props.classes.responsiveConOne}>
          <Box className={this.props.classes.profileOfferCon}>
            <Box className={`${this.props.classes.profileCon} ${this.props.classes.profileConMaxWidthCon}`}>
              {this.state.isBakcgroundActive ?
                <Box data-testid="avatar" style={{backgroundColor: this.state.proposalUserDetails.background_color}} className={this.props.classes.profileImageCon} />
                : <img
                  data-testid = "image"
                  onError={this.handleErrorImage}
                  src={this.baseUrlStirng+this.state.proposalUserDetails.profile_photo}
                  alt={configJSON.image}
                  className={this.props.classes.profileImage}
                />}
              <Box className={`${this.props.classes.userDescriptionCon} ${this.props.classes.userDetailsAdjustment}`}>
                <Typography variant="h2">{this.state.proposalUserDetails.name}</Typography>
                <Typography variant="body2">{this.state.proposalUserDetails.location}</Typography>
                <Typography variant="body2" className={`${this.props.classes.viewProfileText} ${this.props.classes.fontWeightBold} ${this.props.classes.underline}`}>{configJSON.viewProfileText}</Typography>
              </Box>
              <Box className={`${this.props.classes.allPropsCon} ${this.props.classes.userDetailsAdjustment}`}>
                <img src={messageIcon} alt={configJSON.image} />
                <Typography variant="body2" className={`${this.props.classes.fontWeightBold} ${this.props.classes.underline}`}>{configJSON.messageText}</Typography>
              </Box>
            </Box>
            {this.renderShortListText()}
          </Box>
          <Box className={`${this.props.classes.profileOfferCon} ${this.props.classes.responsiveHeight}`}>
            <Box className={this.props.classes.profileConMaxWidthCon}>
              <Box className={this.props.classes.borderCon}>
                <Typography variant="body2" className={this.props.classes.profileInfoHeading}>{configJSON.viewText}</Typography>
                <Typography variant="body1">{this.state.proposalUserDetails.views}</Typography>
              </Box>
              <Box className={this.props.classes.borderCon}>
                <Typography variant="body2" className={this.props.classes.profileInfoHeading}>{configJSON.likesText}</Typography>
                <Typography variant="body1">{this.state.proposalUserDetails.likes}</Typography>
              </Box>
              <Box className={this.props.classes.borderCon}>
                <Typography variant="body2" className={this.props.classes.profileInfoHeading}>{configJSON.submittedRateText}</Typography>
                <Typography variant="body1">{`${configJSON.rupeeSymbol}${this.state.proposalUserDetails.submitted_rate} ${configJSON.prPerHourText}`}</Typography>
              </Box>
              <Box className={this.props.classes.borderCon}>
                <Typography variant="body2" className={this.props.classes.profileInfoHeading}>{configJSON.ratings}</Typography>
                <Box className={`${this.props.classes.allPropsCon} ${this.state.proposalUserDetails.ratings as number >= 4.0 ? this.props.classes.greenBg : this.props.classes.redBg}`}>
                  <Typography variant="body1">
                    {this.isRatingZero() ? this.state.proposalUserDetails.ratings : configJSON.newText}
                  </Typography>
                 {this.isRatingZero() &&  <img src={ratingIcon} alt={configJSON.image} /> }
                </Box>
              </Box>
            </Box>
           {this.renderSkillsCon()}
          </Box>
        </Box>
        <Box className={this.props.classes.borderBottomCon} />
        <Box className={this.props.classes.responsiveConTwo}>
          <Typography variant="h4" className={this.props.classes.responsiveMargin}>
            {configJSON.submittedWorkText}
          </Typography>
          {this.renderCollectionOfProjects()}
          <Typography variant="h4" className={`${this.props.classes.responsiveMargin} ${this.props.classes.reponsiveHeightTwo}`}>
            {configJSON.pitchText}
          </Typography>
          <Typography variant="body1" >
            {this.state.proposalUserDetails.give_your_pitch}
          </Typography>
          <Box className={`${this.props.classes.hourlyCon} ${this.props.classes.responsiveMargin}`}>
            <Typography variant="h4" >{configJSON.hourlyRateText}</Typography>
            <Typography variant="body2" className={this.props.classes.hourRateDescription}>
              {`${configJSON.hourlyRateDescription} ${this.state.proposalUserDetails.rate_amount} ${this.isPerHourAdded()} ${configJSON.closingBracket}`}
            </Typography>
          </Box>
          <Box className={`${this.props.classes.chip} ${this.props.classes.chipTwo} `}>
            <Typography variant="body2" className={this.props.classes.hourlyRateValueText}>
              {`${configJSON.rupeeSymbol}${this.state.proposalUserDetails.hourly_rate} ${configJSON.prPerHourText}`}
            </Typography>
          </Box>
          <Box className={this.props.classes.overEstimationCon}>
            <Box>
              <Box className={this.props.classes.overAllEstTextCon}>
                <Typography variant="body2">{configJSON.overAllEstText}</Typography>
              </Box>
              <Box className={`${this.props.classes.borderTopCon}`}>
                <Box className={this.props.classes.overAllEstTextCon}>
                  <Typography variant="body2" className={this.props.classes.shadowTextOne}>
                    {`${this.state.proposalUserDetails.work_timeline} ${this.state.proposalUserDetails.work_timeline_type}`}
                  </Typography>
                </Box>
                <Box className={`${this.props.classes.overAllEstTextCon} ${this.props.classes.borderLeftCon}`}>
                  <Typography variant="body2" className={this.props.classes.shadowTextOne}>
                    {`${this.state.proposalUserDetails.required_hours_per_week} ${configJSON.hoursPerWeekText}`}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box className={`${this.props.classes.overAllEstTextCon} ${this.props.classes.borderLeftCon}`}>
              <Typography variant="body1">
                {`${configJSON.rupeeText} ${this.state.proposalUserDetails.overall_project_estimate}`}
              </Typography>
            </Box>
          </Box>
          <Typography variant="h4" className={this.props.classes.responsiveMargin}>
            {configJSON.startDateText}
          </Typography>
          <Box className={this.props.classes.dateTextNewCon}>
            <Typography>{this.state.proposalUserDetails.start_date}</Typography>
          </Box>
          <Typography variant="h4" className={this.props.classes.responsiveMargin}>
            {configJSON.additionalTextHeading}
          </Typography>
            {this.renderAdditionalFiles()}
        </Box>
      </Box>
    </Box>)
  }

  // Customizable Area End

  render() {
    return (

      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <NavigationMenu {...this.props} />
        <Backdrop className={this.props.classes.backdrop} open={this.state.isAPIFetching}>
          <CircularProgress className="circular" />
        </Backdrop>
        <Box className={this.props.classes.container}>
          {
            this.state.isLoading ?
              this.renderBackDrop() :
              this.renderPropsDetails()
          }
          <ToastMSG data-test-id="toastMessage" close={this.handleCloseToast} message={this.state.message} open={this.state.open} action={this.state.action} />
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const lineBreakToOneLine = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: "1",
  WebkitBoxOrient: "vertical" as "vertical",
  wordBreak:"break-word" as "break-word",
}

const styles = {
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor: "transparent",
    height: "calc(100vh - 76px)",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .circular": {
      color: "#fff"
    }
  },
  responsiveHeight: {
    marginTop: "2.5rem"
  },
  reponsiveHeightTwo: {
    marginTop: "3.5rem",
  },
  responsiveMargin: {
    marginBottom: "2rem",
  },
  gridCon: {
    margin: "1.5rem 0",
    padding: 0,
    marginLeft: "-1rem",
  },
  container: {
    width: '100%',
    backgroundColor: "#111111",
    display: "flex",
    "& *": {
      boxSizing: "border-box",
    }
  },
  responsiveCon: {
    width: "min(100%,1490px)",
    margin: "0 auto",
    padding: "5rem 3rem",
    
  },
  revisedAllPropsCon: {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
  },
  allPropsCon: {
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
    alignSelf: "flex-start"
  },
  reviewText: {
    color: typoBlack,
  },
  workpostText: {
    margin: "2rem 0 1rem 0",
  },
  middleCon: {
    backgroundColor: "#171717",
    width: "100%",
    minHeight: "30vh",
    borderRadius: "10px",
  },
  responsiveConOne: {
    padding: "3rem 3rem 2rem 3rem",
  },
  responsiveConTwo: {
    padding: "3rem",
  },
  profileCon: {
    display: "flex",
    gap: "1.5rem",
  },
  profileImageCon: {
    width: "90px",
    height: "90px",
    borderRadius: "50%",
  },
  profileImage: {
    width: "90px",
    height: "90px",
    objectFit: "contain" as "contain",
    borderRadius: "50%",
  },
  userDetailsAdjustment: {
    paddingTop: ".35rem",
  },
  userDescriptionCon: {
    display: "flex",
    flexDirection: "column" as "column",
    gap: "0.25rem",
    justifyContent: "space-between",
  },
  viewProfileText: {
    color: "#828282"
  },
  fontWeightBold: {
    fontWeight: 700,
    fontFamily: "Inter-SemiBold, sans-serif",
  },
  underline: {
    textDecoration: "underline",
    cursor: "pointer",
  },
  cursonPointer: {
    cursor: "pointer",
  },
  shortListText: {
    letterSpacing: "0.38px",
    cursor: "pointer",
  },
  buttonCon: {
    display: "flex",
    gap: "1rem",
    alignSelf: "center",
  },
  archiveButton: {
    backgroundColor: "#0D0D0D",
    color: typoWhite,
    padding: "10px 30px",
    "&:hover": {
      backgroundColor: "#0D0D0D",
    }
  },
  offerContractButton: {
    backgroundColor: typoWhite,
    color: "#121212",
    padding: "10px 30px",
    "&:hover": {
      backgroundColor: typoWhite,
    }
  },
  profileOfferCon: {
    display: "flex",
    justifyContent: "space-between",
    gap: "2rem"
  },
  shortListButtonsCon: {
    display: "flex",
    flexDirection: "column" as "column",
    gap: "1rem",
    justifyContent: "space-between",
  },
  marginLeftAuto: {
    marginLeft: "auto",
  },
  profileConMaxWidthCon: {
    display: "flex",
    minWidth: "470px",
    flexShrink: 0,
  },
  borderCon: {
    display:'flex',
    flexDirection: "column" as "column",
    gap: ".5rem",
    "&:not(:last-child)": {
      borderRight: `1.24px solid #545454`,
    },
    padding: "0rem 1rem",
    alignSelf: "flex-start",
  },
  profileInfoHeading: {
    color: "#7D7D7D",
  },
  greenBg: {
    backgroundColor: "#349E4B",
    borderRadius: "3px",
    padding: ".2rem .5rem",
  },
  redBg: {
    backgroundColor: "#545454",
    borderRadius: "3px",
    padding: ".2rem .5rem",
  },
  chipCon: {
    display: "flex",
    gap : ".75rem",
    flexWrap: "wrap" as "wrap",
    flexGrow: 1,
  },
  chip: {
    backgroundColor: "#1D1D1D",
    borderRadius: "24px",
    height: "26px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px 22px"
  },
  chipTwo: {
    backgroundColor: "#363636",
    width: "fit-content",
    marginBottom: "2rem"
  },
  ratePerHourText: {
    color: "#1CBAE0"
  },
  borderBottomCon: {
    borderBottom: ".97px solid #545454"
  },
  hourlyCon: {
    display: "flex",
    gap: "1rem",
    alignItems: "center",
    marginTop: "4.5rem",
  },
  hourRateDescription: {
    color: "#545454"
  },
  overEstimationCon: {
    backgroundColor: "#202020",
    display: "flex",
    borderRadius: "11.97px",
    width: "fit-content",
    marginBottom: "3rem",
  },
  overAllEstTextCon: {
    display: "flex",
    alignItems: "center",
    padding: "1rem",
    color: "#545454",
  },
  durationHourCon: {
    display: "flex"
  },
  borderLeftCon: {
    borderLeft: "1px solid #4A4A4A"
  },
  shadowTextOne: {
    color: "#545454",
    "&:not(:last-child)": {
      borderRight: "1px solid #4A4A4A",
    }
  },
  borderTopCon: {
    borderTop: "1px solid #4A4A4A",
    display: "flex",
  },
  hourlyRateValueText:{
    color: "#1CBAE0"
  },
  startDateDesTextCon: {
    border: "1.16px solid #575656",
    borderRadius: "33px",
    width: "fit-content",
    padding: ".75px 1.25px",
  },
  filesListCon: {
    padding: 0,
    margin: 0,
    listStyleType: "none"
  },
  fileList: {
   display: "flex",
   gap: "1rem",
   alignItems: "center",
   marginBottom: "1.5rem",
  },
  fileNameCon: {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
  },
  fileCon: {
    backgroundColor: "#252525",
    display:"flex",
    alignItems: "flex-start",
    padding: ".8rem 1.25rem",
    width: "215px",
    overflow: "hidden",
    borderRadius: "10px",
  },
  countTypo: {
    width: "1rem",
    color: "#545454",
    fontWeight: 600,
  },
  collectionNameText: {
    color: "#66625C",
    marginBottom: "1rem",
  },
  collectionCon: {
    display: "flex",
    gap: "2rem", 
    flexWrap: "wrap" as "wrap"
  },
  collectionCard: {
    width: "min(100%,413.84px)",
  },
  coverImage: {
    width: "100%",
    height: "285.89px",
    objectFit: "cover" as "cover",
    borderRadius: "10px",
  },
  dateTextNewCon: {
    display: "flex",
    padding: ".8rem 1.25rem",
    justifyContent: "center",
    alignItem: "center",
    border: "1.16px solid #575656",
    borderRadius: "33px",
    marginBottom: "4rem",
    width: "fit-content",
  },
  projectNameCon: {
    display: "flex",
    gap: "1rem",
    alignItems: "flex-start",
    justifyContent: "space-between",
    marginTop: "1rem",
  },
  projectName: {
    fontFamily: "Inter-SemiBold, sans-serif",
  },
  iconsCon: {
    display: "flex",
    gap: "1rem",
  },
  iconCon: {
    display: "flex",
    flexDirection: "column" as "column",
    gap: ".25rem",
    alignItems: "center",
    "& img": {
      width: "26.59px",
      height: "18.13px",
      objectFit: "contain" as "contain",
    }
  },
  iconInfo: {
    fontSize: "0.625rem",
  },
  blueColorText: {
    color: "#528ADF",
    cursor: "pointer",
  }
  

};

const Proposalgeneration = withStyles(styles)(Proposalgeneration2);

export { Proposalgeneration }

// Customizable Area End
