// Customizable Area Start
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const Search = require("../assets/Search.png");
export const close = require("../assets/close.png");
export const check = require("../assets/check.png");
export const check_img = require("../assets/check.png");
export const close_img = require("../assets/close.png");
export const left_img = require("../assets/left.png");
export const pdf_img = require("../assets/pdf.png");
export const search_img = require("../assets/Search.png");
export const Settings = require("../assets/Settings.png");
export const Insights = require("../assets/Insights.png");
export const ShareIcon = require("../assets/ShareBtn.png");
export const HeartIcon = require("../assets/HeartIcon.png");
export const EyeIcon = require("../assets/ShowIcon.png");
export const ListIcon = require("../assets/ListIcon.png");
export const rightArrow = require("../assets/MoreViewBtn.png");

// Customizable Area End
