  // Customizable Area Start
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import storage from "../../../../framework/src/StorageProvider.web";
import { ChangeEvent } from 'react';
import axios from "axios";
import {setErrorProfileValue,setErrorEditProfileEducationSection,checkErrorEditProfileUser,updateProfileState,updateProfileStateError,getExpertiseNonSelectedListData,handleViewMoreCssBeforeAdd,handleEditProfileSKillsCssAfterAdd,createDataTemp} from "../../../../components/src/Base64Conversion";
import { Message } from "framework/src/Message";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { getStorageData, setStorageData } from "framework/src/Utilities";
export const configJSON = require("./../config");
export interface Props {
  navigation: any;
  id: string;
  handleCloseEditProfile:()=>void

}

interface Language {
  label: string;
   value: string;
}
interface Skill {
  id: number;
  select: boolean;
}
interface Expertise {
  value: {
    id: number;
  };
  skills?: Skill[];
}
interface Education {
  id: number
  qualification?: string;
  year_from?: string;
  year_to?: string;
  description?: string;
}

interface S {
  address:string
  editProfileData:any;
  expertiseTempData:any[];
  skillsTempData:any[];
  editProfileError:any;
  tokenData:string|null;
  userProfileID:string|null;
  languages:any[];
  expertiseSelected:any;
  skillsSelected:any[];
  viewMore:boolean;
  inputValueLanguage:string;
  filteredOptionsLanguage:any;
  filteredOptionsExpertise:any;
  inputValueExpertise:string;
  enteredLangQuery:string;
  enteredExpQuery:string;
  userRole:string;
  industryList:any;
  designationList:any;
  designation_id:any;
  industry_id:any;
  companyName:string;
  countcharacter:number;
  suggestions: string[];
  open:boolean;
  action:string;
  message: string;
  location:string;
  country:string;
  toastopen:boolean
}

interface SS {
  id: any;
}

export default class EditProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  userProfilesImageGetApiCallId:string = "";
userDesignationListCallId :string = "";
userIndustryListCallId :string = "";



  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);   
    this.subScribedMessages = [ 
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.NavigationTargetMessage),     
      getName(MessageEnum.SessionSaveMessage),      
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    
    ];
    this.state = {
      address:"",
      country:"",
      languages:[{ label: "English", value: "English" },
        { label: "Hindi", value: "Hindi" },
        { label: "Bengali", value: "Bengali" },
        { label: "Spanish", value: "Spanish" },
        { label: "Punjabi", value: "Punjabi" },
        { label: "Tamil", value: "Tamil" },
        { label: "Telugu", value: "Telugu" },
        { label: "Urdu", value: "Urdu" },
      ],
      expertiseSelected:"",
      expertiseTempData:[],
      skillsTempData:[],
      editProfileData:{name:"",website:"",insta:"",linkedin:"",dribble:"",location:"",Country:"",
        about:"",rateMin:"",rateMax:"",expertise:[],
        education:[{qualification:"",year_from:"",year_to:"",description:"",id:""}],language:[],skills:[],companyName:"",designation:"",industry:""},
      editProfileError: {
        name: '',
        website: '',
        insta: '',
        companyName:"",
        linkedin: "",
        dribble: "",
        location: "",
        about: "",
        rateMin: "",
        rateMax: "",
        expertise: [],
        education: [
          {
            qualification: "",
            year_from: "",
            year_to: "",
            description: "",
          },
        ],
        language: [],
      },
      tokenData: "",
      userProfileID: "",
      skillsSelected: [],
      viewMore: false,
      inputValueLanguage: "",
      filteredOptionsLanguage: [],
      filteredOptionsExpertise: [],
      inputValueExpertise: "",
      enteredLangQuery: "",
      enteredExpQuery: "",
      userRole: "",
      industryList: [],
      designationList: [],
      designation_id: "",
      industry_id: "",
      companyName: "",
      countcharacter:0,
      suggestions: [],
      open:false,
      action:"",
      message: "",
      location:"",
      toastopen:false
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }
  async receive(from: string, message: Message) {
  
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
  
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );   
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      if (apiRequestCallId === this.userProfilesImageGetApiCallId) {   
        
       
        if (responseJson.errors) {
         
         this.setState({toastopen:true,action:"danger",message:`${responseJson.errors[0]},${responseJson.errors[1]||""}` })
         
        } else {
          
          let existingData = await getStorageData('userInfo'); 
          const parsedData = existingData ? JSON.parse(existingData) : { type: '', attributes: {} }; 
        
          parsedData.data.attributes.country = responseJson.data.attributes.country;
          parsedData.data.attributes.first_name = responseJson.data.attributes.name;
          parsedData.data.attributes.city = responseJson.data.attributes.city;
          await setStorageData('userInfo', JSON.stringify(parsedData)); 
          this.props.handleCloseEditProfile()      
        }
      }

      this.handleDesignAndIndustry(apiRequestCallId,responseJson)
      
    }
  }
  handleDesignAndIndustry =(apiRequestCallId:string,responseJson:any)=>{
    if (apiRequestCallId === this.userDesignationListCallId) {
      this.HandleDesignation(responseJson)
    }
    if(apiRequestCallId === this.userIndustryListCallId) {
      this.industryResponse(responseJson)
    }

  }
  handleClose = () => {
    this.setState({ toastopen: false });
  }
  HandleDesignation = (responseJson:any) =>{
    this.setState({designationList: responseJson.data})
  }
  industryResponse =(responseJson:any) =>{
   
    this.setState({industryList:responseJson.data});
    
  }
  async componentDidMount() {
    super.componentDidMount();
    storage.get("userInfo").then(res => {
      let storeData = JSON.parse(res);
      this.setState({userRole:storeData.data.attributes.role_name})
      if (storeData && storeData.meta) {
        this.setState({tokenData:storeData.meta.token, userProfileID:storeData.meta.id})
        this.getIndustryList(storeData.meta.token)
        this.getDesignationList(storeData.meta.token)
        
      }
    }).then(()=>{
      this.getExpertiseList()
    }).then(()=>{
      this.getProfileList()
      
    })

    this.setState({filteredOptionsLanguage:this.state.languages})
    
  }
  handleCountCharacter = (e:any) =>{
    
    const characterCount = typeof e === 'string' ? e.length : 0;
  
    this.setState({ countcharacter: characterCount });
  }
  

  handleDesignationChange= (event:any) =>{
    
    this.setState({ designation_id: event.target.value });
  }

  handleIndustrychange = (event:any) =>{
    this.setState({ industry_id: event.target.value });
  }

  getDesignationList =(Token:any) =>{
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userDesignationListCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiDesignation
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      token: Token
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getIndustryList =(Token:any) =>{
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userIndustryListCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiIndusrtry
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      token: Token
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateEditProfileData=(data:any)=>{  
    this.setState({editProfileData:data})
  }

  updateEditProfileDataWithError=(data:any,field:string)=>{
    this.updateEditProfileData(data)
    this.handleBlur(field,data,true)
    
  }

  updateEditProfileDataWithErrorEd=(data:any,index:number,field:string)=>{
    this.updateEditProfileData(data)
    this.handleBlurEducation(index,field)
  }

  expertiseHandler=(item:any)=>{  
    this.setState({enteredExpQuery:'',filteredOptionsExpertise:this.state.expertiseTempData})
    if(this.state.expertiseSelected==item)
    this.setState({expertiseSelected:"",skillsSelected:[]})
  else
    this.setState({expertiseSelected:item,skillsSelected:[]})
    this.getSkillsList(item?.id)
  }

  handleAddNewExpertise=()=>{
    const skills=this.state.skillsTempData.map((item)=>{
      return ({value:item.name,select:this.state.skillsSelected.includes(item.name),id:item.id})
    })
    this.state.editProfileData.expertise=[...this.state.editProfileData.expertise,
      {value:this.state.expertiseSelected,skills:[...skills],view_more:false}]
    this.setState({editProfileData:this.state.editProfileData,expertiseSelected:"",skillsTempData:[]})

   }

   handleSkillsInAddedList=(index:number,indexSkills:number)=>{
      if(this.state.editProfileData.expertise[index]?.skills) {   
      this.state.editProfileData.expertise[index].skills[indexSkills].select = 
      !this.state.editProfileData.expertise[index].skills[indexSkills].select
      this.setState({editProfileData: this.state.editProfileData})
      }
   }

   handleMultiSelect=({ min, max }:any) =>{
      this.state.editProfileData.rateMin=min;
      this.state.editProfileData.rateMax=max;
      this.setState({editProfileData:this.state.editProfileData})
    }

  handleSkillViewMore=(index:any)=>{
    this.state.editProfileData.expertise[index].view_more=!this.state.editProfileData.expertise[index].view_more
    this.setState({editProfileData:this.state.editProfileData})
  }

  getSkillsList=(id:any)=>{
    const headers={
      token:this.state.tokenData?this.state.tokenData:"",
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'If-Modified-Since': '0',
    }
      axios.get(`${configJSON.baseURL.baseURL}/${configJSON.getAllSkills}${id}`,{ headers }).then((res)=>{
      const resData = res.data.data;
      this.setState({
        skillsTempData:resData
          })
    })
  }

  handleExpertiseSelect=(value:any)=>{
    const expertiseObj=this.state.expertiseTempData.find((item)=>item.name==value.label)
    this.setState({expertiseSelected:expertiseObj})
    this.getSkillsList(expertiseObj?.id)
  }

  viewMoreText=(index:number)=>{
    return !this.state.editProfileData.expertise[index].view_more?"View more":"View less"
  }

  handleCapitalizeFirst = (name: string) =>{
    return name?.charAt(0).toUpperCase() + name?.slice(1)
  }

  handleCapitalizeOptions = (options : Language[]) => {
    return options.map(option => ({
      label: this.handleCapitalizeFirst(option.label),
      value: option.value,
    }));
  };

  getExpertiseList=()=>{
    const headers={
      token:this.state.tokenData?this.state.tokenData:"",
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'If-Modified-Since': '0',
    }
      axios.get(`${configJSON.baseURL.baseURL}/${configJSON.getAllExpertise}`,{ headers }).then((res)=>{
      const resData = res.data.data;    
      this.setState({
               expertiseTempData:resData,
               filteredOptionsExpertise:resData,
          })
    })
  }


  getYearList=()=>{
    const years:any = [];
    const startYear = 1900;
    const endYear = new Date().getFullYear();
   
  
    for (let year = startYear; year <= endYear; year++)
     {
        years.push(year);
    }
    return years
  }

  checkErrorEditProfile=(index:any)=>{
    return checkErrorEditProfileUser(index,this.state.editProfileError,this.state.editProfileData)
  }


  handleBlur=(field:string,data:string,fromChange:any=false)=>{
  let error=''
  error = setErrorProfileValue(field,data)
  if(fromChange&&error)
  error=""
  this.setState({editProfileError:{...this.state.editProfileError,[field]:error}})


}

handleSuggestionSkills=(item:any)=>{
  if(!this.state.skillsSelected.includes(item.name))
  {this.state.skillsSelected.push(item.name)
  this.setState({skillsSelected: this.state.skillsSelected})}
  else  {
    const skillFilterArr = this.state.skillsSelected.filter(i => i !== item.name)
    this.setState({skillsSelected: skillFilterArr})}
}

handleBlurEducation=(index:number,field:string)=>{
  let error=''
  error=setErrorEditProfileEducationSection(field,this.state.editProfileData,index)
  this.state.editProfileError.education[index][field]=error
  this.setState({editProfileError:this.state.editProfileError})

}

handleViewMore=()=>{
  this.setState({viewMore: !this.state.viewMore})
}

getProfileList=()=>{
  const headers={
    token:this.state.tokenData?this.state.tokenData:"",
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'If-Modified-Since': '0',
  }
    axios.get(`${configJSON.baseURL.baseURL}/${configJSON.getAllProfiles}/${this.state.userProfileID}`,{ headers }).then((res)=>{
    const resData = res.data.profile_data;   
    this.getExpertiseDataSavedOnNetwork(resData,this.state.expertiseTempData)
    this.setState({
        editProfileData:updateProfileState(resData,this.state.editProfileData,this.state.editProfileError),
      editProfileError: updateProfileStateError(resData,this.state.editProfileData,this.state.editProfileError),
      location:resData.profile_details.city
      
        },()=>{this.handleCountCharacter(this.state.editProfileData.about)})
  })
}

handleskillslistupdate =(data:any,skillls:any)=>{
  const skillIds = skillls.map((ele:any) => ele.id);

  const updatedData = Object.keys(data).reduce((acc:any, key) => {
    const expertiseItem = data[key];

    if (!expertiseItem || !Array.isArray(expertiseItem.skills)) {
      acc[key] = expertiseItem; 
      return acc;
    }
    acc[key] = {
      ...expertiseItem,
      skills: expertiseItem.skills.map((skill:any) => ({
        ...skill,
        select: skillIds.includes(skill.id) 
      }))
    };
    return acc;
  }, {});

  this.setState(prevState => {
    return {
      editProfileData: {
        ...prevState.editProfileData,
        expertise: Object.values(updatedData) 
      }
    };
  });
}


getExpertiseDataSavedOnNetwork=async(resData:any,expertiseList:any)=>{
if(!!resData?.profile_details?.expertise_category.length)
 { 
  const expertiseIdArr=resData?.profile_details?.expertise_category
  const expertiseFilterArr=[]

  for (let i of expertiseIdArr) {
    const ele = expertiseList.find((item: any) => item.name == i);
    if (ele) {
      const skillsData = await this.getSkillsListForExpertise(ele?.id);
      const dataTemp=createDataTemp(ele,skillsData,this.state.editProfileData)
      expertiseFilterArr.push(dataTemp);
    }
  }

  if(expertiseFilterArr.length)
   { this.state.editProfileData.expertise=this.handleskillslistupdate(expertiseFilterArr,resData.profile_details.skills)
  this.setState({editProfileData: this.state.editProfileData})}

}
  
}

getSkillsListForExpertise=(id:any)=>{
  const headers={
    token:this.state.tokenData?this.state.tokenData:"",
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'If-Modified-Since': '0',
  }
   return axios.get(`${configJSON.baseURL.baseURL}/${configJSON.getAllSkills}${id}`,{ headers })
}


updateProfileHandler = async (fromDelete = false, idRec = "") => {
  const { editProfileData,expertiseSelected,skillsSelected } = this.state;
let skillsIDArray:number[] =[]
let expertiseIDArray = []

  
  const  skillsTemp = editProfileData && editProfileData.expertise && 
  editProfileData.expertise.length > 0 ? editProfileData.expertise.map((exp:Expertise) => exp.skills && exp.skills.filter((skill:Skill) => skill.select).map((skill:Skill) => skill.id)) : [];;
  skillsIDArray =[].concat(...skillsTemp)
  
  expertiseIDArray = editProfileData && editProfileData.expertise && 
  editProfileData.expertise.length > 0 ? editProfileData.expertise.map((exp:Expertise) => exp.value.id) : [];
  // editProfileData.city = this.state.address

  if (expertiseSelected !=""){

    const filteredObjects =this.state.skillsTempData.filter((obj:any) => skillsSelected.includes(obj.name));
    expertiseIDArray.push(expertiseSelected.id)
    for(let ele of filteredObjects){
     skillsIDArray.push(ele.id)
    }

  }

  if (fromDelete) {
    editProfileData.education = [
      {
        id: idRec,
        _destroy: 1,
      },
      ...editProfileData.education,
    ];
  }

  const tokens = await getStorageData("userInfo");
  const newToken = JSON.parse(tokens);
  const {
    meta: { token, id },
  } = newToken;
  
  const headers = {
    token: token,
  };

  const formData = this.createFormData(editProfileData, expertiseIDArray, skillsIDArray);

  this.addEducationDataToForm(formData, editProfileData.education, fromDelete, idRec);

  const getValidationMsg = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.userProfilesImageGetApiCallId = getValidationMsg.messageId;
  getValidationMsg.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.endPointApiGetUserImageProfile + `${id}`
  );
  getValidationMsg.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    headers
  );
  getValidationMsg.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.apiUpdateUserType
  );
  getValidationMsg.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    formData
  );

  runEngine.sendMessage(getValidationMsg.id, getValidationMsg);
};

handleCompanyAttributes (formData:any,editProfileDataEntered:any) {
   formData.append("profile[company_attributes][company_name]",editProfileDataEntered.companyName);
  editProfileDataEntered.designation !== undefined  && formData.append("profile[company_attributes][designation_id]",editProfileDataEntered.designation);
  editProfileDataEntered.designation !== undefined &&  formData.append("profile[company_attributes][industry_id]",editProfileDataEntered.industry)

}

createFormData = (editProfileDataEntered:any, expertiseIDArray:any, skillsIDArray:any) => {
  const formData = new FormData();
  formData.append("profile[name]", editProfileDataEntered.name);
  formData.append("profile[website_link]", editProfileDataEntered.website || "");
  formData.append("profile[dribble]", editProfileDataEntered.dribble || "");
  formData.append("profile[linkedin]", editProfileDataEntered.linkedin || "");
  formData.append("profile[instagram]", editProfileDataEntered.insta || "");
  formData.append("profile[about]", editProfileDataEntered.about || "");
  formData.append("profile[city]", editProfileDataEntered.location || "") ;
  formData.append("profile[country]",editProfileDataEntered.Country || "");
  formData.append(
    "profile[languages]",
    editProfileDataEntered.language
      .map((i: { id: string; label: string }) => i.label)
      .join(",") || ""
  );
  formData.append("profile[min_per_hour]", editProfileDataEntered.rateMin || "600");
  formData.append("profile[max_per_hour]", editProfileDataEntered.rateMax || "600");
  formData.append("profile[expertise_category]", JSON.stringify(expertiseIDArray));
  formData.append("profile[skills]", JSON.stringify(skillsIDArray));
  {this.state.userRole !== "designer" && 

  this.handleCompanyAttributes (formData,editProfileDataEntered) 
    
   
  

  
  
   }
  

  return formData;
};
handleChangeLocationText = (data: string) => {
  this.setState({location:data,address:data})
}


addEducationDataToForm = (formData:any, educationData:any, fromDelete:any, idRec:any) => {
 
  if (educationData.length > 0) {
    
    educationData.forEach((education: Education, index: number) => {
      formData.append(`profile[educations_attributes][${index}][qualification]`, education.qualification || "");
      formData.append(`profile[educations_attributes][${index}][year_from]`, education.year_from || this.getYearList()[0]);
      formData.append(`profile[educations_attributes][${index}][year_to]`, education.year_to || this.getYearList()[0]);
      formData.append(`profile[educations_attributes][${index}][description]`, education.description || "");
      
  
      if (fromDelete && education.id === idRec) {
        formData.append(`profile[educations_attributes][${index}][_destroy]`, "1");
      }
    });
  } else {
    formData.append(`profile[educations_attributes][${0}][qualification]`, "");
    formData.append(`profile[educations_attributes][${0}][year_from]`, "" );
    formData.append(`profile[educations_attributes][${0}][year_to]`,"");
    formData.append(`profile[educations_attributes][${0}][description]`, "");
  }
    
  
  
};

 

getExpertiseNonSelectedList=()=>{
  return getExpertiseNonSelectedListData(this.state.expertiseTempData)
}

handleLangSelect=(arr:any)=>{
  this.updateEditProfileData({
    ...this.state.editProfileData,
    language: [...arr],
  })
}

handleCloseExpertise=()=>{
  this.setState({expertiseSelected:""})
}

handleCloseLanguage=()=>{
  this.updateEditProfileData({
    ...this.state.editProfileData,
    language: [],
  })
}

handleExpertiseDelete=(item:any,index:any)=>{
  
  this.state.editProfileData.expertise= this.state.editProfileData.expertise.filter((i:any)=>item != i)
  this.setState({editProfileData:this.state.editProfileData})
}

handleViewMoreCss=()=>handleViewMoreCssBeforeAdd(this.state.viewMore)

handleEditProfileSKillsCss=(item:any)=>handleEditProfileSKillsCssAfterAdd(item)


handleLanguageSelect=(item:any)=>{
  this.setState({enteredLangQuery:'',filteredOptionsLanguage:this.state.languages})
  if(this.state.editProfileData.language.filter((i: any) =>  item.label == i.label).length)
  {
    this.setState({editProfileData:{...this.state.editProfileData,language: [...this.state.editProfileData.language.filter((i: any) =>  item.label != i.label)],
    }})

  }
  
else
 { this.updateEditProfileData({
    ...this.state.editProfileData,
    language: [...this.state.editProfileData.language, item],
  })}
}

handleEducationDelete = (index: number,item:any) => {
  const formData = new FormData();
  formData.append(`profile[educations_attributes][${index}][id]`, item.id);
  this.updateEditProfileData({
    ...this.state.editProfileData,
    education: this.state.editProfileData.education.filter((item: any, i: number) =>index !== i),
  });
}

handleCancelEditProfile=()=>{
  this.props.handleCloseEditProfile()
}

handleSaveEditProfile=()=>{
  this.updateProfileHandler()
}

handleAddClick=()=>{
    var emptyFields = []
  
    this.state.editProfileData.education.forEach((education: Education) => {
        Object.keys(education).forEach(key =>{
          if(education.description === "" || education.qualification === "" || education.description == "" || education.qualification == "" ){
            emptyFields.push(key)
          }
  
        })
    })
    if(emptyFields.length <=0 ){
      this.updateEditProfileData({
        ...this.state.editProfileData,
        education: [
          ...this.state.editProfileData.education,
          {qualification:null,year_from:null,
            year_to:null,description:null},
        ],
      })
     
      this.setState({editProfileError:{
        ...this.state.editProfileError,
        education: [
          ...this.state.editProfileError.education,
          {qualification:null,year_from:null,
            year_to:null,description:null},
        ],
      }})
    }
}

getNameEditProfile=()=>(
  this.state.editProfileData.name
? this.state.editProfileData.name
: "")
getCompanyName = () =>(
  this.state.editProfileData.companyName?
  this.state.editProfileData.companyName:""
)
getDesignation =() =>(
  this.state.editProfileData.designation?
  this.state.editProfileData.designation:""
)
getIndustry = () =>(
  this.state.editProfileData.industry?
  this.state.editProfileData.industry:""
)
 
getwebEditProfile=()=>(
  this.state.editProfileData.website
  ? this.state.editProfileData.website
  : "")

getinstaEditProfile=()=>(this.state.editProfileData.insta
    ? this.state.editProfileData.insta
    : "")

getlinkEditProfile=()=>(this.state.editProfileData.linkedin
      ? this.state.editProfileData.linkedin
      : "")
getDribbleEditProfile=()=>(this.state.editProfileData.dribble
        ? this.state.editProfileData.dribble
        : "")

getlocationEditProfile=()=>(this.state.editProfileData.location
          ? this.state.editProfileData.location
          : "")

          handleChangeLocation = (data: string) => {
            let values = data.split(',')
            
            this.setState({address:data, location:values[0], country:values[values.length - 1]})
            
            this.updateEditProfileData( {
              ...this.state.editProfileData,
              Country: values[values.length - 1],
              location:values[0]
            })
           
        }
        handlePressEnter = (e: React.KeyboardEvent<HTMLDivElement>) =>{
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }
        handleChangeLocationTextBlur = () => {
          if(!this.state.location){
            this.setState({address:""})
          }
        }

getAoutEditProfile=()=>( this.state.editProfileData.about
  ? this.state.editProfileData.about
  : "")

getSelectExpertiseEditProfile=()=>(this.state.expertiseSelected&&{label:this.state.expertiseSelected?.name,value:this.state.expertiseSelected?.name})

handleInputChangeLang=(inputValueLanguage:any)=>{
  this.setState({ inputValueLanguage }, this.filterOptionsLanguage);
  return inputValueLanguage;
}

filterOptionsLanguage = () => {
  const { inputValueLanguage } = this.state;
  const filteredOptionsLanguage = this.state.languages.filter(option =>
    option.label.toLowerCase().includes(inputValueLanguage.toLowerCase())
  );
  this.setState({ filteredOptionsLanguage });
};

handleInputChangeLanguage = (e:ChangeEvent<HTMLInputElement>) => {
  const value = e.target.value;
  this.setState({enteredLangQuery:value})
  if (value) {
      const filtered = this.state.languages.filter((item) => 
          item.label.toLowerCase().includes(value.toLowerCase())
      );
      this.setState({filteredOptionsLanguage:filtered})
  } else {
    this.setState({filteredOptionsLanguage:this.state.languages})
  }
};

handleInputChangeExpertiseSkills = (e:ChangeEvent<HTMLInputElement>) => {
  const value = e.target.value;
  this.setState({enteredExpQuery:value})
  if (value) {
      const filtered = (this.state.expertiseTempData || []).filter((item) => 
          (item.name || "").toLowerCase().includes(value.toLowerCase())
      );
      this.setState({filteredOptionsExpertise:filtered})
  } else {
    this.setState({filteredOptionsExpertise:this.state.expertiseTempData})
  }
};

handleExpertiseTempData = (data: {
  id: string | number
  name: string,
  created_at: string,
  updated_at: string}[]) => {
  this.setState({ expertiseTempData: [...data]});
}

handleInputChangeExpertise=(inputValueExpertise:any)=>{
  this.setState({ inputValueExpertise }, this.filterOptionsExpertise);
  return inputValueExpertise;
}

filterOptionsExpertise = () => {
  const { inputValueExpertise } = this.state;
  const filteredOptionsExpertise = this.state.expertiseTempData.filter((option:any) =>
  option.name.toLowerCase().includes(inputValueExpertise.toLowerCase())
  );
  this.setState({ filteredOptionsExpertise });
};

 getErrorValuesAsString=(): boolean =>{
  let value=false
  let obj=this.state.editProfileError
  let data=this.state.editProfileData
    for (let key in obj) {
      if(typeof(obj[key])=="string"&&obj[key]?.length){
        value=true;
        break;
      }
    }
  
    for (let item of data.education) {
      if((item.year_from>item.year_to)){
        value=true;
        break;
      }
    }
    return value
  }

}


  // Customizable Area End