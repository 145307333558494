// Customizable Area Start
import React, { FormEvent, MouseEvent } from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getToken } from "../../../components/src/Base64Conversion";
import { setStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("./config");

export interface Props {
    navigation: any;
    cardId: string;
    
}

export interface UpdateShortList {
    proposal: Proposal
    message: string
  }
  
  export interface Proposal {
    id: number
    proposal_status: string
    work_opportunity_id: number
    account_id: number
    service_rate: number
    give_your_pitch: string
    links: string
    start_date: string
    terms_and_conditions: boolean
    created_at: string
    updated_at: string
    answer: string[]
  }

  export interface UpdatePending {
    data: UpdatePendingData[],
    error: string
  }
  
  export interface UpdatePendingData {
    id: string
    type: string
    attributes: UpdatePendingAttributes
  }
  
  export interface UpdatePendingAttributes {
    id: number
    created_at: string
    updated_at: string
    service_rate: number
    give_your_pitch: string
    profile_photo: string
    background_color: string
    location: string
    user_name: string
    likes_count: number
    view_count: number
    rating: number
    received_time_ago: string
  }
  
  
interface S {
    isSearched: boolean;
    selectedEventTab: number;
    loading: boolean;
    loader:boolean;
    token: string;
    pendingDataList: UpdatePendingData[];
    ProposalNotFound:string;
    locationSearchData: string[]
    openSortDropDown: boolean;
    SelectedDropDownLocationName: string[];
    SelectedDropDownRatingName: string[];
    openLocationDropDown: boolean;
    openRatingDropDown: boolean;
    SelectedDropDownName: string;
    searchInput: string;
    searchLocationInput: string;
    showIcon: boolean;
    page: number;
    perPage: number;
    checkBoxIndex: string[] | null;
    selectedTab: string,
    changeTab: boolean,
    location: string,
    address: string,
    country: string
    ratingData: any;
    sortData: any;
    openToast: boolean;
    toastMessage: string;
    toastHeading: string;
}
interface SS {
    id: any;
}
export default class ProposalsController extends BlockComponent<
    Props,
    S,
    SS
> {

    pendingListDataCallId: string = "";
    pendingListDataScrollCallId: string = "";
    locationDataCallId: string = "";
    apiUpdateShortlistCallId: string = '';
    baseUrlStirng:string = configJSON.baseURL.baseURL;

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.NavigationPayLoadMessage),  
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationTargetMessage),
        ];
        this.state = {
            isSearched: false,
            selectedEventTab: 0,
            loading: false,
            loader:true,
            token: '',
            pendingDataList: [],
            ProposalNotFound:"",
            locationSearchData: [],
            openSortDropDown: false,
            SelectedDropDownName: "",
            openLocationDropDown: false,
            openRatingDropDown: false,
            SelectedDropDownLocationName: [],
            SelectedDropDownRatingName: [],
            searchInput: "",
            searchLocationInput: "",
            showIcon: false,
            page: 1,
            perPage: 6,
            checkBoxIndex: [],
            selectedTab: "pending",
            changeTab: false,
            location: "",
            address: "",
            country: "",
            openToast: false,
            toastMessage: '',
            toastHeading: '',
            sortData: [
                {
                    "id": 1,
                    "name": "Oldest to latest",
                    "value": "Oldest to latest"
                },
                {
                    "id": 2,
                    "name": "Latest to Oldest",
                    "value": "Latest to Oldest"
                },
                {
                    "id": 3,
                    "name": "Service Rate: Low to high",
                    "value": "Service Rate: Low to high"
                },
                {
                    "id": 4,
                    "name": "Service Rate: High to low",
                    "value": "Service Rate: High to low"
                },
                {
                    "id": 5,
                    "name": "Ratings",
                    "value": "Ratings"
                }
            ],

            ratingData: [

                {
                    "id": 2,
                    "name": "1.0-2.0",

                },
                {
                    "id": 3,
                    "name": "2.0-3.0",
                },
                {
                    "id": 4,
                    "name": "3.0-4.0",
                },
                {
                    "id": 5,
                    "name": "4.0-4.5",
                },

            ]

        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.hanleApiResposne(apiRequestCallId, responseJson)
        }
    }

    getTokenData = async () => {
        const token = await getToken();
        this.setState({ token: token })
    };

    async componentDidMount() {
        await this.getTokenData();
    }

    hanleApiResposne = (apiRequestCallId: any, responseJson: any) => {
        if (apiRequestCallId === this.pendingListDataScrollCallId && responseJson) {

            this.setPendingListDataScroll(responseJson)
        }
        switch (apiRequestCallId) {
            case this.apiUpdateShortlistCallId:
                this.setUpdateShortListData(responseJson)
                break;
            case this.pendingListDataCallId:
                this.setPendingListData(responseJson)
                break;
            case this.locationDataCallId:
                if (responseJson.locations) {
                    this.setState({
                        locationSearchData: responseJson.locations,
                        loader: false
                    })
                }
                break;
            default:
                break;
        }
    }

    setUpdateShortListData = (responseJson: UpdateShortList) => {
        if (responseJson.proposal) {
            this.setState({ toastHeading: "Success", toastMessage: responseJson.message, openToast: true, page: 1 }, () => { this.pendingList() });
        } else {
            this.setState({ toastHeading: "danger", toastMessage: responseJson.message, openToast: true, page: 1 }, () => { this.pendingList() });
        }
    }

    setPendingListData = (responseJson: UpdatePending) => {
        if (responseJson) {
            this.setState({
                loader: false,
                pendingDataList: responseJson.data,
                ProposalNotFound: responseJson.error,
                showIcon: this.state.searchInput ? true : false
            })
        }
        if ((responseJson.data && responseJson.data.length === 0) || responseJson.error) {
            this.setState({
                ProposalNotFound: 'no found',
            })
        }
    }

    setPendingListDataScroll = (responseJson: UpdatePending) => {
        this.setState((prevState) => ({
            loader: false,
            pendingDataList: [...prevState.pendingDataList, ...responseJson.data],
            ProposalNotFound: responseJson.error,
            showIcon: this.state.searchInput ? true : false,


        }))

        if ((responseJson.data && responseJson.data.length === 0) || responseJson.error) {
            this.setState({
                ProposalNotFound: 'no found',
            })
        }
    }

    debouncedHandleScroll = (event: any) => {
        this.handleScroll(event)
    }
    handleScroll = (event: any) => {
        const scrollableDiv = event;
        if ((Math.abs(scrollableDiv.scrollHeight - scrollableDiv.scrollTop - scrollableDiv.clientHeight) < 1) && this.state.pendingDataList.length%6 === 0) {
            this.setState({
                page: this.state.page + 1
            }, () => {
                this.pendingListScroll()
            })
        };
    }
    handleSort = () => {
        this.pendingList()
    }

    handleRating = () => {
        this.pendingList()
    }

    handleTabClick = (tabName: any) => {
        this.setState({ selectedTab: tabName }, () => {
            this.clearFilter()
        });
    };

    dropDownViewData = (element: any) => {
        this.setState({ SelectedDropDownName: element.name }, () => {
            this.handleSort();
        })
    }

    handleCardID = (value: number, nav: boolean, status: string) => {
        if (nav) {
            setStorageData("ProposalgenerationID", value)
            const message: Message = new Message(getName(MessageEnum.NavigationMessage));
            message.addData(getName(MessageEnum.NavigationTargetMessage),
                `Proposalgeneration`);
            message.addData(getName(MessageEnum.NavigationPropsMessage),
                this.props);
            this.send(message);
        } else {
            this.handleShortListStatus(status, value)
        }
    };

    handleClose() {
        this.setState({ openToast: false, toastMessage: "" });
    }

    handleShortListStatus = (status: string, value: number) => {
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.state.token,
        }

        const httpBody = {
            data: {
                attributes: {
                    proposal_status: status
                }
            }
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiUpdateShortlistCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.updateProposalStatusEndPoint}?id=${value}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    ratingStatiData = (value: any) => {

        this.setState((prevState) => {
            const { SelectedDropDownRatingName } = prevState;
            if (SelectedDropDownRatingName.includes(value)) {
                return {
                    SelectedDropDownRatingName: SelectedDropDownRatingName.filter(item => item !== value),
                    checkBoxIndex: null
                };
            } else {
                return {
                    SelectedDropDownRatingName: [...SelectedDropDownRatingName, value],
                    checkBoxIndex: value
                };
            }
        }, () => {
            this.handleRating();
        });
    }

    filterLocationData = (value: any) => {

        this.setState((prevState) => {
            const { SelectedDropDownLocationName } = prevState;
            if (SelectedDropDownLocationName.includes(value)) {
                return {
                    SelectedDropDownLocationName: SelectedDropDownLocationName.filter(item => item !== value),
                    checkBoxIndex: null
                };
            } else {
                return {
                    SelectedDropDownLocationName: [...SelectedDropDownLocationName, value],
                    checkBoxIndex: value
                };
            }
        }, () => {
            this.pendingList();
        });
    }


    clearText = () => {
        this.setState({ isSearched: false, searchInput: "" }, () => {
            this.pendingList()

        })
    }

    handleDisableSearch = () => {
        if (this.state.searchInput.length === 0) {
            return true
        } else {
            return false
        }
    }

    handleSearch = (e: any) => {
        this.setState({ searchInput: e.target.value })

    }

    handleLocationSearch = (event: any) => {
        this.setState({ searchLocationInput: event?.target.value })

    }


    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {


        if (this.props.cardId !== prevProps.cardId) {
            if (this.props.cardId) {
                this.setState({
                    page: 1,
                    SelectedDropDownRatingName: [],
                    SelectedDropDownName: "",
                    SelectedDropDownLocationName:[]

                }, () => {
                    this.pendingList()
                    this.fetchLocationData()
                })
            }
        }
    }

    pendingList = () => {
        this.setState({ loading: true,loader:true });
        let token = this.state.token;
        const location=this.state.SelectedDropDownLocationName.map((locationName)=>`&location[]=${locationName.toLowerCase()}`).join("");
        const rating=this.state.SelectedDropDownRatingName.map((ratingName) => `&rating[]=${ratingName.toLowerCase()}`).join("").replace(/[\s:]/g, '_')
        const header = {
            token: token
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.pendingListDataCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.pendingDataEndPoint}?work_opportunity_id=${this.props.cardId}&page=${this.state.page}&per_page=${this.state.perPage}&search=${this.state.searchInput}&proposal_status=${this.state.selectedTab}&sort=${this.state.SelectedDropDownName.toLowerCase().replace(/[\s:]/g, '_')}${rating}${location}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    };

    pendingListScroll = () => {
        this.setState({ loading: true });
        let token = this.state.token;
        const location=this.state.SelectedDropDownLocationName.map((locationName)=>`&location[]=${locationName.toLowerCase()}`).join("");
        const rating=this.state.SelectedDropDownRatingName.map((ratingName) => `&rating[]=${ratingName.toLowerCase()}`).join("").replace(/[\s:]/g, '_')
        const header = {
            token: token
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.pendingListDataScrollCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.pendingDataEndPoint}?work_opportunity_id=${this.props.cardId}&page=${this.state.page}&per_page=${this.state.perPage}&search=${this.state.searchInput}&proposal_status=${"pending"}&sort=${this.state.SelectedDropDownName.toLowerCase().replace(/[\s:]/g, '_')}${rating}${location}`);

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    };

    fetchLocationData = () => {
        this.setState({ loading: true });
        let token = this.state.token;
        const header = {
            token: token
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.locationDataCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.locationDataEndPoint}?work_opportunity_id=${this.props.cardId}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    };

    clearFilter = () => {
        this.setState({ searchInput: "",
            SelectedDropDownRatingName: [],
            SelectedDropDownLocationName: [],
            SelectedDropDownName: '',
            openRatingDropDown: false,
            openLocationDropDown: false,
            openSortDropDown: false,
            page: 1
         }, () => {
            this.pendingList()
        });
    }

    handleSubmitSearch = (event: FormEvent<HTMLFormElement> | MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
        this.pendingList()
    }

    // Customizable Area End
}