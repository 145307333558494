import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import SendbirdChat, {
  SendbirdChatWith,
  User,
} from '@sendbird/chat';
import { 
  UserMessageCreateParams, 
  UserMessage, 
  PreviousMessageListQueryParams,
  } from "@sendbird/chat/message";
import {
  GroupChannelModule,
  SendbirdGroupChat,
  GroupChannel,
} from "@sendbird/chat/groupChannel";
import { createRef, RefObject } from "react";

import { getStorageData } from "../../../framework/src/Utilities";
import { userProfile } from "./assets";

export const SendBird_Info = { appId: "3F0A1410-6329-4AC3-ADFF-AAADE8C5D8E7"}
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start

interface IUserMessage {
  id: number,
  message: string,
  senderId: string,
  createdAt: number,
  profileUrl: string,
}

interface IUserList {
  user_id: string;
  nickname: string;
  profile_url: string;
  require_auth_for_profile_image: boolean;
  metadata: {};
  created_at: number;
  is_hide_me_from_friends: boolean;
  is_online: boolean;
  last_seen_at: number;
  is_active: boolean;
  has_ever_logged_in: boolean;
  preferred_languages: never[];
  discovery_keys: never[];
  phone_number: string;
}
export interface IChat {
  id: string;
  muted: boolean;
  unreadCount: number;
  lastMessage: string;
  name: string;
}
interface IChatResponse {
  id: string;
  attributes: {
    name: string;
    accounts_chats: [
      {
        id: string;
        attributes: {
          account_id: number;
          muted: boolean;
          unread_count: number;
        };
      }
    ];
    messages: {
      id: string;
      attributes: { id: number; message: string };
      message: string;
    };
  };
}
// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: Record<string, string>
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  accountId: number;
  chatName: string;
  chatList: IChat[];
  isVisibleModal: boolean;
  activeMessageTab: string;
  user: User | null;
  channel: GroupChannel | null;
  userId: string;
  receiverId: string;
  messages: UserMessage[];
  input: string;
  error: string;
  addNewChannel: boolean;
  dezinerToken: string;
  userList: IUserList[];
  channelUrl: string;
  messagesDateWise: Record<string, IUserMessage[]>;
  channelList: { cover_url: string, channel_url: string }[];
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChatController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getChatListApiCallId: string = "";
  createChatRoomApiCallId: string = "";
  sendBird: SendbirdChatWith<GroupChannelModule[]> | null = null;
  suggestedUsersId: string = "";
  channelListId: string = "";
  lastMessageRef: RefObject<HTMLDivElement> = createRef();
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      accountId: -1,
      chatName: "",
      chatList: [],
      isVisibleModal: false,
      activeMessageTab: configJSON.tabsData[0].tabId,
      user: null,
      channel: null,
      userId: "",
      receiverId: "106",
      channelUrl: "private_chat_room_25_106",
      messages: [],
      input: "",
      error: "",
      addNewChannel: false,
      dezinerToken: "",
      userList: [],
      channelList: [],
      messagesDateWise: {}
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    this.getTokenFromLocalStorage();
    this.initializeSendBird();
    if (!this.isPlatformWeb()) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  isStringNullOrBlank = (string: string) => {
    return string === undefined || string === null || string.length === 0;
  };

  showModal = () => {
    this.setState({ isVisibleModal: true });
  };

  hideModal = () => {
    this.setState({ isVisibleModal: false });
  };

  navigateToChatView = (chatId: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ViewChat");

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      chatId: chatId,
    });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };

  getChatList = async (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getChatListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMyChatsApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createChatRoom = (chatName: string) => {
    if (this.isStringNullOrBlank(chatName)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory,
        ""
      );
    } else {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.state.token,
        "Access-Control-Allow-Origin": "*",
      };
      const bodyData = {
        name: chatName,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.createChatRoomApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createChatRoomApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(bodyData)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  inputRoomNameProps = {
    onChangeText: (chatName: string) => {
      this.setState({ chatName });
    },
  };

  btnAddRoomProps = {
    onPress: () => this.createChatRoom(this.state.chatName),
  };

  btnCloseModalProps = {
    onPress: () => this.hideModal(),
  };

  btnShowAddModalProps = {
    onPress: () => {
      this.showModal();
    },
  };

  handleChatNameChange = (chatName: string) => {
    this.setState({ chatName });
  };

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (errorResponse) this.parseApiCatchErrorResponse(errorResponse);
    if (responseJson?.errors) this.parseApiErrorResponse(responseJson);

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      const token: string = message.getData(
        getName(MessageEnum.SessionResponseToken)
      );
      runEngine.debugLog("TOKEN", token);
      const messageData = JSON.parse(
        message.getData(getName(MessageEnum.SessionResponseData))
      );
      const accountId: number = messageData?.meta?.id;
      this.setState({ accountId });
      if (token) {
        this.setState({ token }, () => this.getChatList(token));
      }
    }
    if (
      responseJson?.data &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getChatListApiCallId.length > 0 &&
      apiRequestCallId === this.getChatListApiCallId
    ) {
      this.getChatListApiCallId = "";
      const chatList = responseJson.data;
      const results = chatList.map((item: IChatResponse) => {
        const findAccountMuteResult = item.attributes.accounts_chats.find(
          (item) => item.attributes.account_id === this.state.accountId
        )?.attributes.muted;
        return {
          id: item.id,
          name: item.attributes.name,
          muted:
            findAccountMuteResult ??
            item.attributes.accounts_chats[0].attributes.muted,
          unreadCount:
            item.attributes.accounts_chats[0].attributes.unread_count,
          lastMessage: item.attributes.messages?.attributes?.message,
        };
      });
      this.setState({
        chatList: results,
      });
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.createChatRoomApiCallId.length > 0 &&
      apiRequestCallId === this.createChatRoomApiCallId &&
      responseJson
    ) {
      this.createChatRoomApiCallId = "";
      this.hideModal();
      this.getChatList(this.state.token);
    }
    this.handleMessages(message);
  }

  handleActiveTab = (tabName:string) => {
    this.setState({activeMessageTab: tabName})
  }

  isActiveTab = (tabId: string) => {
    return this.state.activeMessageTab === tabId ? "activeTab" : ""
  }

  getTokenFromLocalStorage = async () => {
    let tokenDetail = await getStorageData("userInfo");
    const tokenParseData = JSON.parse(tokenDetail);
    let token: string = "";
    let userId: string = "";
    if (tokenParseData && tokenParseData.meta) {
      (tokenParseData.meta.token) && (token = tokenParseData.meta.token);
      (tokenParseData.meta.id) && (userId = tokenParseData.meta.id);
    }
    this.setState({ dezinerToken: token, userId }, () => {
      this.getSuggestedUser();
      this.getChannelsList();
      this.initializeSendBird();
    })
  }

  getSuggestedUser = () => {
    const message = this.createMessage(configJSON.listUserEndPoint,configJSON.getApiMethod)
    this.suggestedUsersId = message.messageId;
    runEngine.sendMessage(message.id,message)
  }

  getChannelsList = () => {
    const message = this.createMessage(configJSON.channelListEndPoint,configJSON.getApiMethod)
    this.channelListId = message.messageId;
    runEngine.sendMessage(message.id,message)
  }

  handleChateInputChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ input: event.target.value })
  }


  initializeSendBird = async () => {
    const sendBirdChat = SendbirdChat.init({
      appId: SendBird_Info.appId,
      localCacheEnabled: false,
      modules: [
        new GroupChannelModule()
      ]
    }) as SendbirdGroupChat

    try{
      sendBirdChat.connect(SendBird_Info.appId);
      this.sendBird = sendBirdChat;
      const user = await this.sendBird.connect(this.state.userId);
      this.setState({ user })
    }
    catch(error){
      this.setState({ error: 'An unexpected error occurred.' });
    }
 
  }



  connectChannel = async () => {
    if (!!this.sendBird && !!this.state.channelUrl) {

      try {
        const channel = await this.sendBird.groupChannel.getChannel(this.state.channelUrl)
        this.setState({ channel }, this.getMessages);

      } catch (error) {
        this.setState({ error: 'An unexpected error occurred.' });
      }
    }
  }

  getMessages = async () => {
    if (!!this.state.channel) {
      const params: PreviousMessageListQueryParams = {
        limit: 20,
        reverse: false,
        includeReactions: true,
      };
      const query = this.state.channel.createPreviousMessageListQuery(params);
      const messages: unknown[] = await query.load();
      this.setState({ messages: messages as UserMessage[] }, this.parseMessages)
    }
  }

  parseMessages = () => {
    const messagesList: IUserMessage[] = this.state.messages.map((message) => {
      return ({
        id: message.messageId,
        message: message.message,
        senderId: message.sender.userId,
        createdAt: message.createdAt,
        profileUrl: message.sender.profileUrl,
      })
    })
    this.sortDateWise(messagesList)
  }

  getDateString = (timeStamp: number) => {
    const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const dateObj = new Date(timeStamp);

    return `${dateObj.getDate()}/${dateObj.getMonth()}/${dateObj.getFullYear()} ${weekdays[dateObj.getDay()]}`
  }

  sortDateWise = ( messagesList: IUserMessage[]) => {
    let messageDateObject = Object.create({});
    for(let message of messagesList){
      const dateString = this.getDateString(message.createdAt);
      messageDateObject[dateString] = messageDateObject[dateString] ? [...messageDateObject[dateString],message] : [message];
    }
    this.setState({messagesDateWise: messageDateObject})
  }

  sendMessageToChannel = () => {
    if (this.state.input.trim().length > 0 && !!this.state.channel) {
      const params: UserMessageCreateParams = {
        message: this.state.input,
      }
      this.state.channel.sendUserMessage(params)
        .onSucceeded((message) => {
          this.setState(previous => {
            const messageNew: IUserMessage = {
              createdAt: message.createdAt,
              id: message.messageId,
              message: previous.input,
              profileUrl: message.sender.profileUrl,
              senderId: message.sender.userId
            }
            const dateString = this.getDateString(message.createdAt);
            previous.messagesDateWise[dateString] = previous.messagesDateWise[dateString] ? [...previous.messagesDateWise[dateString], messageNew] : [messageNew];
            return ({
              input: "",
              messagesDateWise: previous.messagesDateWise,
            })
          })
          this.scrollToLastMessage();
        })
    }
  }

  getTime = (timeStamp: number) => {
    const dateObject = new Date(timeStamp);
    const hours = (dateObject.getHours() % 12).toString().padStart(2,"0");
    const minutes = (dateObject.getMinutes()).toString().padStart(2,"0");
    const isAm = dateObject.getHours() >= 12 ? 'PM' : 'AM';
    return (`${hours}:${minutes} ${isAm}`);   
  }

  handleErrorImage = (event: React.SyntheticEvent<HTMLImageElement>) => {
    event.currentTarget.src = userProfile;
  }


  toggleNewChannel = () => {
    this.setState(prev => ({ addNewChannel: !prev.addNewChannel }))
  }

  getResponseFromMessage = (message: Message) => { 
    let response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const requestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    return ({ requestCallId, response })
  }

  createMessage = (endPoint: string, method: string) => {
    const contactMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.dezinerToken,
    }
    contactMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    contactMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    contactMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);

    return contactMessage;
  }

  handleMessages = (message: Message) => {
    const { requestCallId } = this.getResponseFromMessage(message);
    if(requestCallId === this.suggestedUsersId){
      this.handleUserList(message);
      return;
    }

    if(requestCallId === this.channelListId){
      this.handleChannelList(message);
    }
  }

  handleUserList = (message: Message) => {
    const { response } = this.getResponseFromMessage(message);
    if(response.data && response.data.users && Array.isArray(response.data.users)){
      this.setState({userList: response.data.users})
    }
  }

  handleChannelList = (message: Message) => { 
    const { response } = this.getResponseFromMessage(message);
    if(response.data && response.data.channels && Array.isArray(response.data.channels)){
      this.setState({channelList: response.data.channels},this.handleSandBird)
    }
  }

  handleSandBird = () => {
    if(this.state.channelList.length > 0) {
      const channelUrl = this.state.channelList[0].channel_url;
      if(!!channelUrl){
        this.setState({ channelUrl },this.connectChannel);
      }
    }  
  }


  async componentWillUnmount()  {
    if(this.sendBird){
      this.sendBird.disconnect();
    }
  }

  scrollToLastMessage = () => {
    if(this.lastMessageRef.current){
      this.lastMessageRef.current.scrollIntoView({ behavior: "smooth" })
    } 
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if(JSON.stringify(prevState.messagesDateWise) !== JSON.stringify(this.state.messagesDateWise)){
      this.scrollToLastMessage()
    }
  }
  
  // Customizable Area End
}
