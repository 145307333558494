import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { fileIcon, photoIcon, plusIcon, searchIcon, sendIcon, userProfile } from "./assets";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import InsertLinkIcon from '@material-ui/icons/InsertLink';

interface ITab {
  tabName: string;
  tabId: string;
  count: number;
}

interface IUserMessage {
  id: number,
  message: string,
  senderId: string,
  createdAt: number,
  profileUrl: string,
}

const typoWhite = "#FFF2E3"

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "Inter, sans-serif",
    h1: {
      color: typoWhite,
      fontSize: "2.25rem",
      fontWeight: 700,
      lineHeight: "24px",
      letterSpacing: "0.38px",
      textAlign: "left",
      fontFamily: "Inter-SemiBold, sans-serif",
      wordWrap: "break-word",
      overflowWrap: "break-word",
    },
    h2: {
      color: typoWhite,
      fontSize: "1.5rem",
      fontWeight: 700,
      lineHeight: "29.05px",
      textAlign: "left",
      fontFamily: "Inter-SemiBold, sans-serif",
      wordWrap: "break-word",
      overflowWrap: "break-word",
    },
    h4: {
      color: typoWhite,
      fontSize: "1.25rem",
      fontWeight: 700,
      lineHeight: "24.2px",
      textAlign: "left",
      fontFamily: "Inter-SemiBold, sans-serif",
      wordWrap: "break-word",
      overflowWrap: "break-word",
    },
    body1: {
      color: typoWhite,
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: "19.36px",
      textAlign: "left",
      wordWrap: "break-word",
      overflowWrap: "break-word",
    },
    body2: {
      color: typoWhite,
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: "16.94px",
      textAlign: "left",
      wordWrap: "break-word",
      overflowWrap: "break-word",
    },
    caption: {
      color: typoWhite,
      fontSize: "0.75rem",
      fontWeight: 400,
      lineHeight: "14.52px",
      textAlign: "left",
      wordWrap: "break-word",
      overflowWrap: "break-word",
    },
    button: {
      color: `${typoWhite}`,
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: "19.36px",
      textTransform: "none",
      letterSpacing: "0.38px",
    }
  }
});
// Customizable Area End

import ChatController, { configJSON, IChat, Props } from "./ChatController";

export default class Chat extends ChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderNewMessagesCon = () => {
    const { classes } = this.props;
    return( 
      <Box className={`${classes.personChatCon} ${classes.newMessagesCon}`}>
        <Typography  variant="h2" className="newMessagesHeading">{configJSON.newMessage}</Typography>
        <Box className={this.props.classes.messageSearchBarCon}>
          <img src={searchIcon} className="icon" />
          <input className="input" placeholder={configJSON.searchPlaceholder} />
        </Box>
        <Typography data-testid="suggestedHeading" variant="h4" className="suggestionsHeading">{configJSON.suggestions}</Typography>
        <Box className="userListCon">
          {this.state.userList.map(
            user => ( <Box className={this.props.classes.userCard} key={user.created_at}>
              <img data-testid="suggestedImage" onError={this.handleErrorImage} src={userProfile} className="userImage" />
              <Box className="userConInfoDesCon">
                <Box className="userInfoCon">
                  <Typography variant="h4" className="userName">{user.nickname}</Typography>
                  <Typography className="activeTime">{user.last_seen_at}</Typography>
                </Box>
                <Typography style={webStyleSetToOneLine}>{configJSON.contactInfo.text}</Typography>
              </Box>
            </Box>)
          )}
        </Box>
      </Box>
    )
  }

  renderMessagesCon = () => {
    return (
      <Box className={this.props.classes.messageCon}>
        <Box className={this.props.classes.messageIconCon}>
          <Typography data-testid='heading' variant="h1">{configJSON.messagesHeading}</Typography>
          <IconButton data-testid="addNewChannel" onClick={this.toggleNewChannel}>
            <img src={plusIcon} className="plusIcon" />
          </IconButton>
        </Box>
        <Box className={this.props.classes.messageSearchBarCon}>
          <img src={searchIcon} className="icon" />
          <input className="input" placeholder={configJSON.searchPlaceholder} />
        </Box>
        <Box className={this.props.classes.tabsCon}>
          {
            configJSON.tabsData.map(
              (tabData: ITab) => (
                <Box data-testid="tabItem" key={tabData.tabId} className={`tabCon`} onClick={this.handleActiveTab.bind(this, tabData.tabId)}>
                  <Typography variant="h4">{`${tabData.tabName} (${tabData.count})`}</Typography>
                  <Box data-testid="tabBorder" className={`gutter ${this.isActiveTab(tabData.tabId)}`} />
                </Box>)
            )
          }
        </Box>
        {this.renderUserCard()}
      </Box>
    )
  }

  renderUserCard = () => {
    return (
      <Box className={this.props.classes.userCardsCon}>
        {this.state.channelList.map((channel: {cover_url:string}) => {
          return (
            <Box className={this.props.classes.userCard}>
              <img src={channel.cover_url} className="userImage" />
              <Box className="userConInfoDesCon">
                <Box className="userInfoCon">
                  <Typography variant="h4" className="userName">{configJSON.contactInfo.name}</Typography>
                  <Typography className="activeTime">{configJSON.contactInfo.activeTime}</Typography>
                </Box>
                <Typography style={webStyleSetToOneLine}>{configJSON.contactInfo.text}</Typography>
              </Box>
            </Box>
          )
        })}
      </Box>
    )
  }
  
  renderChattigInputCon = () => {
    const { classes } = this.props;
    return (
      <Box className={classes.chattingCon}>
        <IconButton>
          <InsertLinkIcon className="insertLink" />
        </IconButton>
        <Menu open={false} MenuListProps={{style: styles.menuItem}}>
          <MenuItem>
            <img src={photoIcon} />
            <Typography>{configJSON.photosAndVideosText}</Typography>
          </MenuItem>
          <MenuItem>
            <img src={fileIcon} />
            <Typography>{configJSON.documentText}</Typography>
          </MenuItem>
        </Menu>
        <input 
          data-testid="messageInput"
          className="chatInput" 
          placeholder={configJSON.typeMessagePlaceholder}
          onChange={this.handleChateInputChange}
          value={this.state.input}
        />
        <IconButton onClick={this.sendMessageToChannel} data-testid="sendButton">
          <img src={sendIcon} />
        </IconButton>
      </Box>
    )
  }

  renderPersonChatCon = () => {
    const { classes } = this.props
    return(
      <Box className={classes.personChatCon}>
        <Box className={this.props.classes.chatHeaderCon}>
          <Box className="userProfileNameCon">
            <img src={userProfile} className="userProfile" />
            <Box className="userNameCon">
              <Typography variant="h4" className="userName">{configJSON.contactInfo.name}</Typography>
              <Typography className="userStatus">{`${configJSON.onlineSymbol}`}</Typography>
            </Box>
          </Box>
          <IconButton>
            <MoreVertIcon className="icon" />
          </IconButton>
        </Box>
        <Box className={this.props.classes.chatHistoryCon}>
          {Object.entries(this.state.messagesDateWise)
          .map((data) => {
            const [date, messages] = data;
            return (
              <Box className="chatWithDateCon">
                <Box className="dateCon">
                  <Box className="horizontalLine"/>
                  <Typography>{date}</Typography>
                  <Box className="horizontalLine" />
                </Box>
                {messages.map( message => {
                  return (this.state.userId === message.senderId ? 
                    this.renderRightChat(message) : 
                    this.renderLeftChat(message))
                })}
              </Box>
            )
          })}
          <div ref={this.lastMessageRef} />
        </Box>
      </Box>
    )
  }

  renderLeftChat = (message: IUserMessage) => {
    const { classes } = this.props;

    return (
      <Box className={`${classes.chatDataCon} ${classes.chatLeft}`}>
        <img onError={this.handleErrorImage} src={configJSON.baseUrl + message.profileUrl} className="image" />
        <Box className="textCon">
          <Typography>{message.message}</Typography>
          <Typography className="timeStampText" variant="caption">{configJSON.timeStamp}</Typography>
        </Box>
      </Box>
    )
  }

  renderRightChat = (message: IUserMessage) => {
    const { classes } = this.props;
    return (
      <Box className={`${classes.chatDataCon} ${classes.chatRight}`}>
        <Box className="textCon rightTextCon">
          <Typography data-testid="rightMessages">{message.message}</Typography>
          <Typography className="timeStampText" variant="caption">{this.getTime(message.createdAt)}</Typography>
        </Box>
        <img onError={this.handleErrorImage} src={configJSON.baseUrl+message.profileUrl} className="image" />
      </Box>
    )
  }
 
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <NavigationMenu id={this.props.id} navigation={this.props.navigation} />
        <Box className={this.props.classes.container}>
          <Box className={this.props.classes.responsiveCon}>
            {this.renderMessagesCon()}
            <Box className={this.props.classes.chatCon}>
              {this.state.addNewChannel ? this.renderNewMessagesCon() :this.renderPersonChatCon()}
              {this.renderChattigInputCon()}
            </Box>
          </Box>
        </Box>
        <Box>
        </Box>     
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyleSetToOneLine = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: "1",
  WebkitBoxOrient: "vertical" as "vertical",
  wordBreak:"break-word" as "break-word"
}
const styles = {
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor: "transparent",
    height: "calc(100vh - 76px)",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .circular": {
      color: "#fff"
    }
  },
  container: {
    width: '100%',
    backgroundColor: "#111111",
    display: "flex",
    height: "calc(100vh - 70px)" ,
    overflow: "hidden",
    "& *": {
      boxSizing: "border-box",
    }
  },
  responsiveCon: {
    width: "min(100%,1490px)",
    height: "100%",
    margin: "0 auto",
    padding: "2rem 3rem",
    display: "flex",
    gap: "1rem",
  },
  messageCon: {
    backgroundColor: "#171717",
    maxWidth: "600px",
    borderRadius: "24px",
    minWidth: "200px",
    padding: "1rem",
    height: "100%",
    display: "flex",
    flexDirection: "column" as "column",
    "& .plusIcon": {
      width: "44px",
      height: "44px",
    }
  },
  messageIconCon: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  messageSearchBarCon: {
    flexShrink: 0,
    display: "flex",
    gap: ".5rem",
    alignItems: "center",
    backgroundColor: "#252525",
    height: "43px",
    width: "100%",
    borderRadius: "43px",
    padding: "0 1rem",
    "& .icon": {
      width: "12px",
      height: "12.84px",
      objectFit: "cover",
    },
    "& .input": {
      outline: "none",
      border: "none",
      height: "100%",
      backgroundColor: "transparent",
      flexGrow: 1,
      color: typoWhite,
      "&::placeholder": {
        color: "#676767",
        fontFamily: "Inter",
      }
    }
  },
  tabsCon: {
    display: "flex",
    borderBottom: `1px solid #FFF2E3`,
    margin: "2rem 1rem 0 1rem",
    "& h4": {},
    "& .tabCon": {
      display: "flex",
      flexDirection: "column" as "column",
      justifyContent: "center",
      alignItems: "center",
      flexGrow: 1,
      flexBasis: "33.333333%",
    },

    "& .gutter": {
      height: 0,
      width: "100%",
      border: "3px solid transparent",
      borderRadius: "1rem",
      marginTop: "1rem",
      flexGrow: 1,
    },
    "& .activeTab": {
      border: "3px solid #FFF2E3",
    },
  },
  userCard: {
    backgroundColor: "#222222",
    borderRadius: "12px",
    padding: "1rem 1.25rem",
    display: "flex",
    gap: "1rem",
    alignItems: "center",
    cursor: "pointer",
    "& .userInfoCon": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "fit-content",
      gap: "1rem",
    },
    "& .userImage": {
      height: "65px",
      width: "65px",
      objectFit: "cover",
      aspectRatio: "1/1"
    }, 
    "& .userName": {
      fontFamily: "Inter-SemiBold, sans-serif",
      position: "relative" as "relative",
      paddingRight: "1rem",
      "&:before": {
        content: '"."',
        position: "absolute" as "absolute",
        right: 0,
        top: "-12%",
      }  
    },
    "& .activeTime": {
      fontFamily: "Inter-SemiBold, sans-serif",
      color: "#8C8C8C",
    },
    "& .userConInfoDesCon": {
      display: "flex",
      gap: ".5rem",
      flexDirection: "column" as "column"
    }
  },
  chatCon: {
    border: "1px solid #292929",
    borderRadius: "13px",
    display: "flex",
    flexDirection: "column" as "column",
    width: "100%",
    height: "100%",
  },
  chatHeaderCon: {
    marginBottom: "auto",
    backgroundColor: "#171717",
    borderTopLeftRadius: "inherit", 
    borderTopRightRadius: "inherit", 
    height: "91px",
    display: "flex",
    justifyContent: "space-between",
    padding: "1rem",
    "& .userProfile": {
      width: "59px",
      height: "59px",
    },
    "& .userName": {
      fontFamily: "Inter-SemiBold, sans-serif",
      position: "relative" as "relative",
    },
    "& .userProfileNameCon": {
      display: "flex",
      gap: "1rem",
      alignItems: "center"
    },
    "& .userNameCon": {
      display: "flex",
      flexDirection: "column" as "column",
      gap: ".5rem"
    },
    "& .userStatus": {
      position: "relative",
      paddingLeft: ".75rem",
      color: "#535353",
      "&:before": {
        content: '"."',
        position: "absolute" as "absolute",
        left: 0,
        top: "-60%",
        fontSize: "2.25rem"
      }
    },
    "& .icon": {
      color: "#D9D9D9",
    }
  },
  chattingCon: {
    height: "89px",
    marginTop: "auto",
    backgroundColor: "#171717",
    borderBottomLeftRadius: "inherit",
    borderBottomRightRadius: "inherit",
    padding: "1rem",
    display: "flex",
    gap: "1rem",
    alignItems: "center",
    "& .insertLink": {
      color: "#888888",
      transform: "rotateZ(-45deg)",
      fontSize: "2rem",
    },
    "& .chatInput": {
      outline: "none",
      border: "none",
      backgroundColor: "#111111",
      flexGrow: 1,
      height: "49px",
      padding: "0 1rem",
      borderRadius: "1rem",
      color: typoWhite,
      "&::placeholder": {
        color: "#7D7D7D",
        fontSize: ".875rem",
        fontFamily: "Inter, sans-serif",
      }
    }
  },
  chatLeft: {
    marginRight: "auto"
  },
  chatRight: {
    marginLeft: "auto",
    justifyContent: "flex-end",
  },
  chatDataCon: {
    display: "flex",
    gap: ".5rem",
    width: "60%",
    "& .image": {
      alignSelf: "flex-end",
      height: "34px",
      width: "34px",
      objectFit: "cover",
      borderRadius: "50%",
    },
    "& .textCon": {
      position: "relative" as "relative",
      backgroundColor: "#323232",
      padding: "1.5rem",
      borderRadius: "1rem 1rem 1rem 0"

    },
    "& .timeStampText": {
      position : "absolute",
      right: ".5rem",
      bottom: ".5rem",
      color: "#8C8C8C",
      backgroundColor: "inherit",
      zIndex: 2,
    },
    "& .rightTextCon": {
      backgroundColor: "#222222",
      borderRadius: "1rem 1rem 0rem 1rem"
    }
  },
  userCardsCon: {
    marginTop: "1rem",
    width: "100%",
    flexGrow: 1,
    overflow: "scroll",
    display: "flex",
    flexDirection: "column" as "column",
    gap: "1rem"
  },
  chatHistoryCon: {
    heght: "calc(100% - 91px)",
    overflow: "auto",
    "& .chatWithDateCon": {
      display: "flex",
      flexDirection: "column" as "column",
      gap: "1rem",
      padding: "1rem",
      height: "100%",
    },
    "& .dateCon": {
      display: "flex",
      gap: ".5rem",
      alignItems: "center",
      width: "calc(100% + 2rem)",
      marginLeft: "-1rem",
    },
    "& .horizontalLine": {
      height: 0,
      borderBottom: `1px solid #292929`,
      flexGrow: 1,
    }
  },

  newMessagesCon: {
    padding: "1.5rem 2rem",
    "& .newMessagesHeading": {
      marginBottom: "1.8rem",
    },
    "& .suggestionsHeading": {
      marginTop: "1.5rem",
      marginBottom: "1rem",
    },
    "& .userListCon": {
      display: "flex",
      flexDirection: "column" as "column",
      gap: "1rem",
      overflow: "auto"
    }
  },
  personChatCon: {
    display: "flex",
    flexDirection: "column" as "column",
    flexGrow: 1,
    height: "calc(100% - 89px)",
    width: "100%",
  },
  menuItem: {
    backgroundColor: "#111111",
    border: "1px solid #5F5F5F",
    borderRadius: "10px",
    padding: 0,
  }
};

const NewChat = withStyles(styles)(Chat);
export { NewChat }
// Customizable Area End
