import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { slider1, slider2, slider3, userProfile } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";


interface IAttribute {
  likes: number;
  views: number;
  location: string;
  image_url: string;
  is_liked: boolean;
  is_viewed: boolean;
  created_at: string;
}
interface IHighlightData extends IAttribute {
 id: number;
 createdAt: string;
}

interface IDateWiseData {
  [key:string]: IHighlightData[]
}

interface IHighlight {
    data: {
      id: string;
      type: string;
      attributes: IAttribute;
    }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: Record<string,string>;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  index: number;
  imgData: any;
  isLoading: boolean;
  token: string;
  dateWiseData: IDateWiseData;
  dateWiseArrayData: IHighlight[][];
  leftDataArray: IHighlight[][];
  rightDataArray: IHighlight[][];
  activeId: number;
  activeCardsActiveIds: number,
  city: string,
  userName: string,
  userProfile: string,
  accountId: null | number;
  page: number;
  perPage: number;
  isBackgroundImageActive: boolean;
  backgroundColor: string,
  baseURL: string;
  open: boolean;
  message: string;
  action: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CarouselDisplayController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  swiper: any;
  highlightsListId: string = "";
  apiPostCallId: string = "";
  apiDeleteCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      index: 0,
      imgData: [
        {
          id: 1,
          img: slider1,
        },
        {
          id: 2,
          img: slider2,
        },
        {
          id: 3,
          img: slider3,
        },
      ],
      isLoading: true,
      token: "",
      dateWiseData: {},
      dateWiseArrayData: [],
      leftDataArray: [],
      rightDataArray: [],
      activeId: 0,
      activeCardsActiveIds: 0,
      userName: "",
      userProfile: "",
      city: "",
      accountId: null,
      page: 1,
      perPage: 31,
      isBackgroundImageActive: false,
      backgroundColor: "",
      baseURL: configJSON.baseURL,
      open: false,
      message: "",
      action: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    
    this.handleResponseData(message)
    // Customizable Area End
  }

  // Customizable Area Start
  thumbnailControl = (index: number) => {
    let currentIndex = this.state.index;
    if (currentIndex !== index) {
      let resultSlide = 0;
      let countSlides = this.state.imgData.length;

      if (index > currentIndex && index !== countSlides) {
        resultSlide = index - currentIndex;
      } else if (index > currentIndex && index === countSlides) {
        resultSlide = currentIndex + 1;
      } else if (index < currentIndex && index !== 0) {
        resultSlide = (currentIndex - index) * -1;
      } else if (index < currentIndex && index === 0) {
        resultSlide = currentIndex * -1;
      }
      this.swiper.scrollBy(resultSlide, true);
    }
  };

   formatDate = (dateString: string) =>  {
    const months = [
      "January", "February", "March", "April", "May", "June", "July", "August",
      "September", "October", "November", "December"
    ];
  
    const dateObj = new Date(dateString);
  
    const day = dateObj.getDate();
    const month = months[dateObj.getMonth()];
    const year = dateObj.getFullYear();
  
    return `${day} ${month}, ${year}`;
  }

  handleResponseData = (message: Message) => {
    const { requestCallId } = this.getResponseFromMessage(message);
    try {
      if (requestCallId === this.highlightsListId) {
        this.handleHighLightResponse(message);
      }
    }
    catch (error) {
      this.setState({
        open: true,
        action: "danger",
        message: configJSON.somethingWentWrong,
      })
    }
  }

  handleHighLightResponse = (message: Message) => {
    const {  errorResponse, response } = this.getResponseFromMessage(message);
    const responsejson:{highlights:IHighlight[][]} = response 
    if (!errorResponse && response.highlights) {
      this.setState({ 
        dateWiseArrayData:  Object.values(responsejson.highlights), 
        rightDataArray: (Object.values(responsejson.highlights)).slice(1)  ,
        isLoading: false,
      })
      return;
    }

    if(!errorResponse && response.message){
      this.setState({
        action: "danger",
        message: response.message,
        open: true
      })
    }
    
  }

  getResponseFromMessage = (message: Message) => { 
    let response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const requestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

    return ({ requestCallId, response, errorResponse })
  }

  createMessage = (endPoint: string, method: string) => {
    const contactMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    }
    contactMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    contactMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    contactMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);

    return contactMessage;
  }

  handleNavigation = (location: string) => {
    const navigateMessage = new Message(getName(MessageEnum.NavigationMessage));
    navigateMessage.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
    navigateMessage.addData(getName(MessageEnum.NavigationTargetMessage),`${location}`);
    this.send(navigateMessage);
  }

  getHighlightDetails = () => {
    const message = this.createMessage(`${configJSON.highlightListEndPoint}?page=${this.state.page}&per_page=${this.state.perPage}&account_id=${this.state.accountId}`,
      configJSON.getAPIEndPoint);
    this.highlightsListId = message.messageId;
    runEngine.sendMessage(message.id,message);
  }

  getToken = async () => {
   
    let tokenDetail = await getStorageData("userInfo");
    const tokenParseData = JSON.parse(tokenDetail);
    let token:string = "";
    let userName: string = "";
    let city: string = "";
    let userProfile: string = "";
    let backgroundColor: string = "";
    let accountId: number = 0;
    if(tokenParseData){
      if(tokenParseData.meta){
        tokenParseData.meta.token && (token = tokenParseData.meta.token);
        tokenParseData.meta.id && (accountId = Number(tokenParseData.meta.id));
      }
      if(tokenParseData.data && tokenParseData.data.attributes){
        ( tokenParseData.data.attributes.first_name) && (userName = tokenParseData.data.attributes.first_name);
        ( tokenParseData.data.attributes.last_name) && (userName += ` ${tokenParseData.data.attributes.last_name}`);
        ( tokenParseData.data.attributes.city) && (city = tokenParseData.data.attributes.city);
        ( tokenParseData.data.attributes.profile_image) && (userProfile = tokenParseData.data.attributes.profile_image);
        ( tokenParseData.data.attributes.background_color) && (backgroundColor = tokenParseData.data.attributes.background_color);
      }
    }
    if(!isNaN(accountId) && accountId !== 0){
      this.setState({ token,city, userName, userProfile,  accountId, backgroundColor: backgroundColor}, this.getHighlightDetails)
    }
  }
  

  handleRightClick = () => {
    this.setState(prev => ({
      rightDataArray: prev.dateWiseArrayData.slice(prev.activeId + 2),
      activeId: prev.activeId === prev.dateWiseArrayData.length - 1 ? prev.activeId : prev.activeId + 1,
      leftDataArray: prev.activeId === prev.dateWiseArrayData.length - 1 ? prev.dateWiseArrayData.slice(0, prev.activeId) : prev.dateWiseArrayData.slice(0, prev.activeId + 1),
      activeCardsActiveIds: 0,
    }
    ))
  }

  handleNavigateToUserProfile = () => {
    this.handleNavigation("UserProfile")
  }

  handleLeftClick = () => {
    this.setState(prev => ({
      rightDataArray: prev.activeId === 0 ? prev.dateWiseArrayData.slice(prev.activeId + 1) : prev.dateWiseArrayData.slice(prev.activeId),
      activeId: prev.activeId === 0 ? prev.activeId : prev.activeId - 1,
      leftDataArray: prev.activeId === 0 ? prev.dateWiseArrayData.slice(0, prev.activeId) : prev.dateWiseArrayData.slice(0, prev.activeId - 1),
      activeCardsActiveIds: 0,
    })) 
  }

  getLeftSideImageData = () => {
   return (this.state.leftDataArray[this.state.leftDataArray.length - 1][0] || {})
  }

  getRightSideImageData = () => {
    return (this.state.rightDataArray[0][0] || {})
  }

  handleLeftActiveId = () => {
    this.setState(prev =>  {
      return(
      {
        activeCardsActiveIds: prev.activeCardsActiveIds === 0 ? 0 : prev.activeCardsActiveIds - 1,
      }
    )})
  }

  handleRightActiveId = () => {
    this.setState(prev => (
      {
        activeCardsActiveIds: (prev.dateWiseArrayData[prev.activeId].length - 1) === prev.activeCardsActiveIds ? prev.activeCardsActiveIds : prev.activeCardsActiveIds + 1   
      }
    ))
  }

  isActiveCard = (idIndex: number) => {
   return this.state.activeCardsActiveIds === (idIndex) ? "activeBar" : ""
  }

  getActiveImageCard = () => {
   return this.state.dateWiseArrayData[this.state.activeId][this.state.activeCardsActiveIds] || {}
  }

  isLeftSideActive = () => {
   return this.state.leftDataArray.length > 0;
  }

  isRightSideActive = () => {
    return (this.state.rightDataArray.length > 0)
  }

  getDaysAgoText = (date: string) => {
    const todayDate = new Date();
    const createdDate = new Date((date || "").split(" ")[0]);

    let diff = Number(todayDate) - Number(createdDate);
    diff = Math.floor(diff / (1000 * 60 * 60 * 24));
    if(diff === 0){
      return configJSON.todayText
    }

    return `${diff} day${(diff !== 1) ? "s" : ""} ago`;
  }

  handleErrorImage = (event: React.SyntheticEvent<HTMLImageElement >) => {
    if(this.state.backgroundColor){
      this.setState({isBackgroundImageActive: true})
    }
    event.currentTarget.src = userProfile;
  }

  handleCloseToast =() => {
    this.setState({
      open: false,
      message: ""
    })
  }

  async componentDidMount(){
    this.getToken()
  }
  // Customizable Area End
}
