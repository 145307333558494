import React from "react";

// Customizable Area Start
import { 
  Box, 
  withStyles, 
  Typography,
  IconButton,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { slider1, leftIcon, rightIcon, eyeIcon, loveIcon, likedIcon } from "./assets";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import CircularProgress from '@mui/material/CircularProgress';
import ToastMSG from "../../../components/src/ToastMSG.web";

interface IAttribute {
  likes: number;
  views: number;
  location: string;
  image_url: string;
  is_liked: boolean;
  is_viewed: boolean;
  created_at: string;
}
interface IHighlight {
  data: {
    id: string;
    type: string;
    attributes: IAttribute;
  }
}

// Customizable Area End

import CarouselDisplayController, {
  Props,
  configJSON,
} from "./CarouselDisplayController";

export default class CarouselDisplay extends CarouselDisplayController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderBackDrop = () => {
    return (
      <Box
        className={this.props.classes.backdrop} >
        <CircularProgress className="circular" />
      </Box>
    )
  }

  renderSideCard = (data: IHighlight) => {
    return (
      <Box
        className={this.props.classes.sideImage}
      >
        <Typography variant="body2" className={this.props.classes.creaeteAt}>
          {this.formatDate(data.data?.attributes?.created_at)}
        </Typography>
        <img src={data.data?.attributes?.image_url} className={"image"} />
      </Box>)
  }

  renderCarousel = () => {
      return (
        <Box className={this.props.classes.responsiveCon}>
          <Box 
            data-testid="headingCon"
            className={this.props.classes.hightLightCon} onClick={() => this.handleNavigateToUserProfile()}>
            <img src={leftIcon} className="leftIcon"/>
            <Typography variant="h2" data-testid="heading">{configJSON.highLightText}</Typography>
          </Box>
          <Box className={this.props.classes.infoCon}>
            <Box className={this.props.classes.sideBarImageSkeltonCon}>
              {(this.isLeftSideActive()) && this.renderSideCard((this.getLeftSideImageData() as IHighlight ))}
            </Box>
            <Box className={this.props.classes.carousalContainer}>
              {<IconButton data-testid="leftArrow" className={`${this.props.classes.buttonsCon} ${!this.isLeftSideActive() && "opacity"}`} onClick={this.handleLeftClick} ><img src={leftIcon} /></IconButton>}
              <Box className="carousalImageCon">
                <img
                  className="carousalImage"
                  data-testid = "activeImage"
                  src={this.getActiveImageCard().data?.attributes?.image_url}
                />
                <Box className="dataContainer">
                  <Box className="barContainer">
                    {
                      this.state.dateWiseArrayData[this.state.activeId].map(
                        (each, idIndex) =>
                          <Box key={idIndex} className={`horizontalBar ${this.isActiveCard(idIndex)}`} />)
                    }
                  </Box>
                  <Box className={this.props.classes.nameIconCon}>
                    <Box className={this.props.classes.nameCon}>
                      {
                        this.state.isBackgroundImageActive ?
                          <Box className="backgroundCon" data-testid="background" style={{backgroundColor: this.state.backgroundColor}} /> :
                          <img
                            data-testid="userProfile"
                            className="img"
                            onError={this.handleErrorImage}
                            src={this.state.baseURL + this.state.userProfile}
                          />
                      }
                      <Box className="nameCityCon">
                        <Typography>{this.state.userName}</Typography>
                        <Typography variant="body2">{this.state.city}</Typography>
                      </Box>
                    </Box>
                    <Box className={this.props.classes.iconsWrapperCon}>
                      <Box className={this.props.classes.iconCon}>
                        <IconButton>
                          <img src={this.getActiveImageCard().data?.attributes?.is_liked ? likedIcon : loveIcon} className="loveIcon" />
                        </IconButton>
                        <Typography variant="body2">{this.getActiveImageCard().data?.attributes?.likes}</Typography>
                      </Box>
                      <Box className={this.props.classes.iconCon}>
                        <IconButton>
                          <img src={eyeIcon} className="eyeIcon" />
                        </IconButton>
                        <Typography variant="body2">{this.getActiveImageCard().data?.attributes?.views}</Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box className={this.props.classes.flipCon}>
                    <Box data-testid="leftSideImage" className="displayCon" onClick={this.handleLeftActiveId} />
                    <Box data-testid="rightSideImage" className="displayCon" onClick={this.handleRightActiveId} />
                  </Box>
                  <Box className={this.props.classes.dateCon}>
                    <Typography>{this.getDaysAgoText(this.getActiveImageCard().data?.attributes?.created_at)}</Typography>
                    <Typography>{this.formatDate(this.getActiveImageCard().data?.attributes?.created_at)}</Typography>
                  </Box>
                </Box>
              </Box>
              <IconButton data-testid="rightArrowIcon" className={`${this.props.classes.buttonsCon} ${!this.isRightSideActive() && "opacity"}`} onClick={this.handleRightClick} ><img src={rightIcon} /></IconButton>
            </Box>
            <Box className={this.props.classes.sideBarImageSkeltonCon}>
              {(this.isRightSideActive()) && this.renderSideCard((this.getRightSideImageData() as IHighlight))}
            </Box>
          </Box>
          <Box className={this.props.classes.lastThirtyDatesCon}>
            <Typography>{configJSON.lastThirtyDays}</Typography>
          </Box>
        </Box>
    )
  }
  // Customizable Area End

  render() {
    console.log(slider1);
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <NavigationMenu {...this.props} />
        <Box className={this.props.classes.container}>
          {
            this.state.isLoading ?
              this.renderBackDrop() :
              this.renderCarousel()
          }
        </Box>
        <ToastMSG
          data-testid="toastMessage"
          close={this.handleCloseToast}
          message={this.state.message}
          open={this.state.open}
          action={this.state.action} 
        />

      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const typoWhite = "#FFF2E3"

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "Inter, sans-serif",
    h2: {
      color: typoWhite,
      fontSize: "2.25rem",
      fontWeight: 700,
      lineHeight: "43.57px",
      textAlign: "left",
      fontFamily: "Inter-SemiBold, sans-serif",
    },
    h4: {
      color: typoWhite,
      fontSize: "1.25rem",
      fontWeight: 700,
      lineHeight: "24.2px",
      textAlign: "left",
      fontFamily: "Inter-SemoBold, sans-serif",
    },
    body1: {
      color: typoWhite,
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: "19.36px",
      textAlign: "left",
    },
    body2: {
      color: typoWhite,
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: "16.94px",
      textAlign: "left",
    },
    caption: {
      color: typoWhite,
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: "14.52px",
      textAlign: "left",
    },
    button: {
      color: `${typoWhite}`,
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: "19.36px",
      textTransform: "none",
      letterSpacing: "0.38px",
    }
  }
});

const webStyle = {
  responsiveCon: {
    width: "min(100%,1490px)",
    margin: "0 auto",
    padding: "5rem 3rem", 
    "& .opacity": {
      opacity: 0,
    }
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor: "transparent",
    height: "calc(100vh - 76px)",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .circular": {
      color: "#fff"
    }
  },
  container: {
    width: '100%',
    backgroundColor: "#111111",
    display: "flex",
    position: "relative" as "relative",
    justifyContent: "center",
    alignItems: "center",
    "& *": {
      boxSizing: "border-box",
    }
  },
  sideBarImageSkeltonCon: {
    height: "414px",
    width: "226px",
  },
  sideImage: {
    height: "100%",
    width: "100%",
    position: "relative" as "relative",
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    padding: "1rem",
    display: "flex",
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      filter: 'drop-shadow(0px 0px 14px rgba(0, 0, 0, 0.4))',
      zIndex: 2,
    },
    "& .image": {
      top: 0,
      left: 0,
      objectFit: "cover",
      width: "100%",
      height: "100%",
      position: "absolute" as "absolute",
      zIndex: 1,
    }
  },
  carousalContainer: {
    display: "flex",
    gap: "1rem",
    alignItems: "center",
    "& .carousalImageCon": {
      width: "488.9px",
      height: "896.13px",
      borderRadius: "10px",
      overflow: "hidden",
      position: "relative",
    },
    "& .carousalImage": {
      width: "100%",
      height: "100%",
      objectFit: "fill",
      boxShadow: '0px 0px 13px 4px #00000040',
      filter: 'drop-shadow(0px 0px 13px 4px #00000040)',
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: 1
    },
    "& .horizontalBar": {
      height: "0",
      borderRadius: "5px",
      border: "3px solid #7D7D7D",
      zIndex: 2,
      width: "auto",
      flexGrow: 1,
    },
    "& .activeBar": {
      border: "3px solid #FFF2E3",
    },
    "& .dataContainer": {
      zIndex: 2,
      position: "absolute",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      display: "flex",
      flexDirection: "column",
    },
    "& .barContainer": {
      display: "flex",
      gap: ".25rem",
      margin: ".5rem"
    }
  },
  creaeteAt: {
    zIndex: 4,
    marginLeft: "auto",
  },
  buttonsCon: {
    backgroundColor: "#8C8C8C",
    width: "56.21px",
    height: "56.21px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: 0,
    "& img": {
      width: "14.05px",
      height: "26.35px",
    },
    "&:hover": {
      backgroundColor: "#8C8C8C",
    }
  },
  nameCon: {
    display: "flex",
    gap: "1rem",
    "& .img": {
      width: "45.6px",
      height: "45.6px",
      objectFit: "cover",
      borderRadius: "50%",
    },
    "& .backgroundCon": {
      width: "45.6px",
      height: "45.6px",
      borderRadius: "50%",
    },
    "& .nameCityCon": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      gap: ".25rem",
      padding: ".25rem 0 .5rem 0"
    }
  },
  iconsWrapperCon: {
    display: "flex",
    gap: "1rem",
  },
  iconCon: {
    display: "flex",
    alignItems: "center",
    "& .loveIcon": {
      width: "28.76px",
      height: "25.31px",
      objectFit: "contain"
    },
    "& .eyeIcon": {
      width: "34.33px",
      height: "22.27px",
      objectFit: "contain"
    }
  },
  nameIconCon: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "1rem",
    width: "100%",
    padding: "0 1.5rem"
  },
  dateCon: {
    borderTop: "1px solid #FFF2E3",
    display: "flex",
    gap: "1rem",
    justifyContent: "space-between",
    width: "100%",
    padding: "1.75rem 2rem",
    marginTop: "auto",
    "& p": {
      fontFamily: "Inter-SemiBold, sans-serif",
    }
  },
  infoCon: {
    display: "flex",
    gap: "2rem",
    alignItems: "center",
    position: "relative" as "relative",
    top: 0,
    left: "50%",
    transform: 'translateX(-44%)',
  },
  flipCon: {
    flexGrow: 1,
    display: "flex",
    width: "100%",
    "& .displayCon": {
      flexGrow: 1,
      height: "100%",
    }
  },
  lastThirtyDatesCon: {
    display: "flex",
    alignItems: 'center',
    gap: "1rem",
    border: "1px solid #66625C",
    width: "fit-content",
    padding: "1rem 1.5rem",
    borderRadius: "13.97px",
    position: "absolute" as "absolute",
    top: "5rem",
    right: "3rem",
  },
  hightLightCon: {
    position: "absolute" as "absolute",
    top: "5rem",
    left: "3rem",
    display: 'flex',
    alignItems: "center",
    gap: ".5rem",
    cursor: "pointer",
    width: "fit-content",
    "& .leftIcon":{
      height: "17px",
      width: "9px",
      objectFit: "contain"
    }
  }
};

const NewCarouselDisplay = withStyles(webStyle)(CarouselDisplay);
export  {NewCarouselDisplay}
// Customizable Area End
