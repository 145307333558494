// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
const config = require("../../../framework/src/config");

import { getToken } from "../../../components/src/Base64Conversion";
import { workOpportunity } from "../../../components/src/OpportunityCard.web";
import { CreativeField } from '../../../components/src/CreativeFields.web'
export interface Props {
    navigation: any;
    id: string;
}








interface S {
    loading: boolean;

    workOppertunityData: any;

    openOppertunityProposal: boolean;
    pendingList: any;
    token: string
    proposalData: any;
    pendingListId: number;
    accordianPop: boolean
}


interface SS {
    id: string;
}

export const configJSON = require("./config");

export default class UnderReviewController extends BlockComponent<Props, S, SS> {
    pendingProposalAPIid: string = "";
    pendingProposalDetailsAPIid: string = ""

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);


        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionRequestMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            loading: false,
            accordianPop: false,
            workOppertunityData: [],
            openOppertunityProposal: false,
            pendingList: [],
            token: "",
            proposalData: [],
            pendingListId: 0

        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    }

    async receive(from: string, message: Message) {
        runEngine.debugLog('Message Recived', message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId == this.pendingProposalDetailsAPIid) {
                this.setState({ loading: false })

                this.setState({ workOppertunityData: responseJson?.work_opportunity_data?.data })
                this.setState({ proposalData: responseJson?.proposal_data?.data })
            }
            if (apiRequestCallId == this.pendingProposalAPIid) {
                this.setState({ pendingList: responseJson?.data })
                if (responseJson && responseJson?.data[0]?.id) {
                    this.setState({ pendingListId: responseJson.data[0].id })
                    this.getPendingDeailsAPI(responseJson.data[0].id)
                }
            }
        }

    }


    getTokenData = async () => {
        const token = await getToken();
        this.setState({ token: token })
    };

    async componentDidMount() {

        await this.getTokenData();
        this.getPendingListAPI()
    }


    getPendingListAPI = async () => {
        this.setState({ loading: true })
        const headers = {
            "Content-Type": "application/json",
            token: this.state.token,
        };
        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.pendingProposalAPIid = getValidationsMsg.messageId
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_cfproposalmanagement/contract_offers/pending_list_proposal`)
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'GET'
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }
    getPendingDeailsAPI = async (id: number) => {

        const headers = {
            "Content-Type": "application/json",
            token: this.state.token,
        };
        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.pendingProposalDetailsAPIid = getValidationsMsg.messageId
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_cfproposalmanagement/contract_offers/pending_proposal_detail?id=${id}`)
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'GET'
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }
    createObjectArray = () => {
        let worldataObj: { number: number; type: string; fileName?: string[]; fileUrl?: string; link?: string; title?: string; image?: string; username?: string; views?: number; likes?: number; savedCount?: number; }[] = [];
        let counter = 0;
        if (Array.isArray(this.state.workOppertunityData?.attributes?.attached_files) && this.state.workOppertunityData?.attributes?.attached_files.length) {
            this.state.workOppertunityData.attributes.attached_files.forEach((file: any) => {
                counter++;
                worldataObj.push({
                    number: counter,
                    type: 'fileName',
                    fileName: [file.file_name],
                    fileUrl: file.url
                });
            });
        }

        if (this.state.workOppertunityData?.attributes?.files_or_links) {
            counter++;
            worldataObj.push({
                number: counter,
                type: 'link',
                link: this.state.workOppertunityData.attributes.files_or_links
            });
        }

        if (Array.isArray(this.state.workOppertunityData?.attributes?.added_projects) && this.state.workOppertunityData.attributes.added_projects.length) {
            this.state.workOppertunityData.attributes.added_projects.forEach((projectData: any, index: number) => {
                counter++;
                worldataObj.push({
                    number: counter,
                    type: 'project',
                    title: projectData.project_title,
                    image: config.baseURL + projectData.project_cover_image,
                    username: projectData.user_name,
                    views: projectData.view_count,
                    likes: projectData.like_count,
                    savedCount: projectData.saved_count
                });
            });
        }
        return worldataObj;
    };
    downloadProjectPdf = async (fileName: string | undefined, pdfUrl: string | undefined) => {

        fetch(pdfUrl || "", { method: "GET" }).then(response => response.blob()).then((blob) => {
            const url = window.URL.createObjectURL(blob);

            const a = document.createElement("a");
            a.href = url;
            a.download = fileName || "pdf-file";
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();

            window.URL.revokeObjectURL(url);

        })
    }
    handleAbutWorkOpen = () => {
        this.setState({ accordianPop: !this.state.accordianPop })

    }
    getRateTypeLabelProposal = (data: any) => {
        const rate_types = data?.attributes?.rate_type;
        if (rate_types && rate_types.includes("hourly")) {
            return "pr/hr";
        }
        return "";
    }


}


// Customizable Area End
