Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CarouselDisplay";
exports.labelBodyText = "CarouselDisplay Body";
exports.btnExampleTitle = "CLICK ME";
exports.highLightText = "Highlights history";
exports.highlightListEndPoint = "/bx_block_landingpage2/highlights/user_highlight_history";
exports.getAPIEndPoint = "GET";
exports.lastThirtyDays = "Last 30 days"
exports.baseURL = require('../../../framework/src/config')?.baseURL;
exports.somethingWentWrong = "Something went wrong";
exports.todayText = "Today";
// Customizable Area End